/* eslint-disable react/prop-types */
import {
  ImagePickerOptions,
  MediaTypeOptions,
  PermissionStatus,
  launchCameraAsync,
  launchImageLibraryAsync,
  requestCameraPermissionsAsync,
  requestMediaLibraryPermissionsAsync,
} from "expo-image-picker";
import React, { useCallback, useState } from "react";
import { Platform, Pressable } from "react-native";
import Animated, { WithTimingConfig, useAnimatedStyle, withTiming } from "react-native-reanimated";
import { COLOR } from "@lookiero/aurora";
import { useLogger } from "@lookiero/sty-psp-logging";
import { NotificationLevel, useCreateToastNotification } from "@lookiero/sty-psp-notifications";
import {
  QuestionItem,
  QuestionType,
  useAnswerForId,
  useAnswersValidation,
  useUploadImage,
} from "@lookiero/sty-psp-quiz-style-profile-common-ui";
import { AspectRatioView, Icon } from "@lookiero/sty-psp-ui";
import { useUploadImage as useUploadImageCommand } from "../../../../../../../infrastructure/domain/image/react/useUploadImage";
import { MESSAGING_CONTEXT_ID } from "../../../../../../delivery/baseBootstrap";
import { useTrackImageUploaded } from "../../../../../../tracking/useTrackImageUploaded";
import { useTrackUploadPhotoChooseLibraryOptionCanceled } from "../../../../../../tracking/useTrackUploadPhotoChooseLibraryOptionCanceled";
import { useTrackUploadPhotoChooseLibraryOptionSelected } from "../../../../../../tracking/useTrackUploadPhotoChooseLibraryOptionSelected";
import { useTrackUploadPhotoError } from "../../../../../../tracking/useTrackUploadPhotoError";
import { useTrackUploadPhotoOptionsModalClose } from "../../../../../../tracking/useTrackUploadPhotoOptionsModalClose";
import { useTrackUploadPhotoOptionsModalView } from "../../../../../../tracking/useTrackUploadPhotoOptionsModalView";
import { useTrackUploadPhotoTakePhotoOptionCanceled } from "../../../../../../tracking/useTrackUploadPhotoTakePhotoOptionCanceled";
import { useTrackUploadPhotoTakePhotoOptionSelected } from "../../../../../../tracking/useTrackUploadPhotoTakePhotoOptionSelected";
import { useTrackUploadPhotoUploadIconSelected } from "../../../../../../tracking/useTrackUploadPhotoUploadIconSelected";
import { useFecthImage } from "../../../../../hooks/useFetchImage";
import { useStaticInfo } from "../../../../../hooks/useStaticInfo";
import { I18nMessages } from "../../../../../i18n/i18n";
import { style } from "./HostImageUploadQuestionItem.style";
import { ImagePickerModal } from "./components/imagePickerModal/ImagePickerModal";

const IMAGE_PICKER_OPTIONS: ImagePickerOptions = {
  mediaTypes: MediaTypeOptions.Images,
  quality: 1,
};

const IMAGE_ANIMATIONS_OPTIONS: WithTimingConfig = {
  duration: 500,
};

const HostImageUploadQuestionItem: QuestionItem<QuestionType.HOST_IMAGE_UPLOAD> = ({ question }) => {
  const {
    customer: { country, customerId, segment },
  } = useStaticInfo();
  const logger = useLogger();
  const [imageLoading, setImageLoading] = useState(false);

  const trackUploadPhotoUploadIconSelected = useTrackUploadPhotoUploadIconSelected({
    country,
    customerId,
    segment,
  });
  const trackUploadPhotoOptionsModalView = useTrackUploadPhotoOptionsModalView({
    country,
    customerId,
    segment,
  });
  const trackUploadPhotoTakePhotoOptionSelected = useTrackUploadPhotoTakePhotoOptionSelected({
    country,
    customerId,
    segment,
  });
  const trackUploadPhotoChooseLibraryOptionSelected = useTrackUploadPhotoChooseLibraryOptionSelected({
    country,
    customerId,
    segment,
  });
  const trackUploadPhotoError = useTrackUploadPhotoError({
    country,
    customerId,
    segment,
  });
  const trackImageUploaded = useTrackImageUploaded({
    country,
    customerId,
    segment,
  });
  const trackUploadPhotoOptionsModalClose = useTrackUploadPhotoOptionsModalClose({
    country,
    customerId,
    segment,
  });
  const trackUploadPhotoChooseLibraryOptionCanceled = useTrackUploadPhotoChooseLibraryOptionCanceled({
    country,
    customerId,
    segment,
  });
  const trackUploadPhotoTakePhotoOptionCanceled = useTrackUploadPhotoTakePhotoOptionCanceled({
    country,
    customerId,
    segment,
  });

  const [imagePickerModalVisible, setImagePickerModalVisible] = useState(false);
  const handleOnImagePickerModalClose = useCallback(() => setImagePickerModalVisible(false), []);

  const [createNotification] = useCreateToastNotification({ contextId: MESSAGING_CONTEXT_ID, logger });
  const handleOnError = useCallback(
    (error?: string) => {
      trackUploadPhotoError();
      createNotification({
        level: NotificationLevel.ERROR,
        bodyI18nKey: error || I18nMessages.UPLOAD_IMAGE_TOAST_GENERIC_ERROR,
      });
    },
    [createNotification, trackUploadPhotoError],
  );

  const [upload, uploadStatus] = useUploadImageCommand({
    logger,
  });
  const uploadImage = useUploadImage({
    onStart: handleOnImagePickerModalClose,
    onSuccess: async ({ id, image }) => {
      await upload({
        id,
        image,
        customerId,
      });

      trackImageUploaded();

      onChange({
        questionId: question.id,
        answer: id,
      });

      validateMaxAnswers();
    },
    onError: handleOnError,
  });

  const { answer, onChange } = useAnswerForId({ id: question.id });
  const selectedAnswer = answer && answer[0];
  const [image, imageStatus] = useFecthImage({
    endpoint: `/view-style-profile-image-by-id/${selectedAnswer}`,
    enabled: Boolean(selectedAnswer),
  });

  const { validateMaxAnswers } = useAnswersValidation();

  const handleOnTakePhoto = useCallback(async () => {
    trackUploadPhotoTakePhotoOptionSelected();

    const response = await requestCameraPermissionsAsync();

    if (response.status === PermissionStatus.GRANTED) {
      try {
        const result = await launchCameraAsync(IMAGE_PICKER_OPTIONS);

        if (result.canceled) {
          trackUploadPhotoTakePhotoOptionCanceled();
          return;
        }

        if (result.assets[0]) {
          await uploadImage({ imagePickerAsset: result.assets[0] });
        }
      } catch (error) {
        handleOnError();
      }
    }
  }, [handleOnError, trackUploadPhotoTakePhotoOptionCanceled, trackUploadPhotoTakePhotoOptionSelected, uploadImage]);
  const handleOnChooseFromLibrary = useCallback(async () => {
    trackUploadPhotoChooseLibraryOptionSelected();

    const response = await requestMediaLibraryPermissionsAsync();

    if (response.status === PermissionStatus.GRANTED) {
      try {
        const result = await launchImageLibraryAsync(IMAGE_PICKER_OPTIONS);

        if (result.canceled) {
          trackUploadPhotoChooseLibraryOptionCanceled();
          return;
        }

        if (result.assets[0]) {
          await uploadImage({ imagePickerAsset: result.assets[0] });
        }
      } catch (error) {
        handleOnError();
      }
    }
  }, [
    handleOnError,
    trackUploadPhotoChooseLibraryOptionCanceled,
    trackUploadPhotoChooseLibraryOptionSelected,
    uploadImage,
  ]);

  const handleOnDismiss = useCallback(() => {
    trackUploadPhotoOptionsModalClose();
    handleOnImagePickerModalClose();
  }, [handleOnImagePickerModalClose, trackUploadPhotoOptionsModalClose]);

  const handleOnPress = useCallback(async () => {
    trackUploadPhotoUploadIconSelected();

    if (Platform.OS !== "web") {
      setImagePickerModalVisible(true);
      trackUploadPhotoOptionsModalView();
    } else {
      await handleOnChooseFromLibrary();
    }
  }, [handleOnChooseFromLibrary, trackUploadPhotoOptionsModalView, trackUploadPhotoUploadIconSelected]);

  const animatedStyle = useAnimatedStyle(
    () => ({
      opacity: withTiming(imageLoading ? 0 : 1, IMAGE_ANIMATIONS_OPTIONS),
    }),
    [imageLoading],
  );
  const handleOnLoadStart = useCallback(() => setImageLoading(true), []);
  const handleOnLoadEnd = useCallback(() => setImageLoading(false), []);

  const loading = uploadStatus === "loading" || imageStatus === "loading" || imageLoading;

  return (
    <>
      <ImagePickerModal
        disabled={loading}
        visible={imagePickerModalVisible}
        onChooseFromLibrary={handleOnChooseFromLibrary}
        onDismiss={handleOnDismiss}
        onTakePhoto={handleOnTakePhoto}
      />

      <AspectRatioView aspectRatio={5 / 4}>
        <Pressable
          accessibilityLabel="host-image-upload-question-item"
          disabled={loading}
          style={style.container}
          testID="host-image-upload-question-item"
          accessible
          onPress={handleOnPress}
        >
          {selectedAnswer && uploadStatus !== "loading" && imageStatus !== "loading" ? (
            <Animated.Image
              resizeMode="cover"
              source={{ uri: image }}
              testID={question.id}
              style={[
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                style.image,
                animatedStyle,
              ]}
              onLoadEnd={handleOnLoadEnd}
              onLoadStart={handleOnLoadStart}
            />
          ) : (
            <Icon color={COLOR.GRAYSCALE_L} name={loading ? "hourglass" : "expand"} />
          )}
        </Pressable>
      </AspectRatioView>
    </>
  );
};

export { HostImageUploadQuestionItem };
