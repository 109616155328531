import React, { FC } from "react";
import { Text } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import { I18nMessages, PREFIX } from "../../../../../../../i18n/i18n";
import { style } from "./StyleProfileSocialDescription.style";

const StyleProfileSocialDescription: FC = () => {
  const description = useI18nMessage({ id: I18nMessages.SOCIAL_SUBTITLE, prefix: PREFIX });

  return (
    <Text level={3} style={style.description}>
      {description}
    </Text>
  );
};

export { StyleProfileSocialDescription };
