import React, { createContext, FC, ReactNode, useContext } from "react";
import invariant from "tiny-invariant";
import { Customer } from "../../../projection/customer/customer";
import { KameleoonEnvironment } from "../../ab-testing/kameleoonEnvironment";

interface StaticInfo {
  readonly apiUrl: () => string;
  readonly getAuthToken: () => Promise<string>;
  readonly basePath: string;
  readonly customer: Customer;
  readonly kameleoon: KameleoonEnvironment;
}

const StaticInfoContext = createContext<StaticInfo>(null as unknown as StaticInfo);

interface StaticInfoProviderProps {
  readonly children: ReactNode;
  readonly apiUrl: () => string;
  readonly getAuthToken: () => Promise<string>;
  readonly basePath: string;
  readonly customer: Customer;
  readonly kameleoon: KameleoonEnvironment;
}

const StaticInfoProvider: FC<StaticInfoProviderProps> = ({
  children,
  apiUrl,
  getAuthToken,
  basePath,
  customer,
  kameleoon,
}) => (
  <StaticInfoContext.Provider value={{ apiUrl, getAuthToken, basePath, customer, kameleoon }}>
    {children}
  </StaticInfoContext.Provider>
);

const useStaticInfo = () => {
  const staticInfo = useContext(StaticInfoContext);

  invariant(
    staticInfo,
    "Your are trying to use the useStaticInfo hook without wrapping your app with the <StaticInfoProvider>.",
  );

  return staticInfo;
};

export { useStaticInfo, StaticInfoProvider };
