import { AggregateRoot, CommandHandlerFunction } from "@lookiero/messaging";
import {
  mapAnswersToInternationalSystem,
  ScreenProjection,
  ValidationProjection,
  viewValidation,
} from "@lookiero/sty-psp-quiz-style-profile-common-ui";
import { viewScreenBySlugAndCustomerId } from "../../../projection/screen/viewScreenBySlugAndCustomerId";
import { FillScreen } from "../command/fillScreen";
import { screenFilled } from "./screenFilled";

type Answers = Record<string, string[]>;

interface Screen extends AggregateRoot {
  readonly customerId: string;
  readonly answers: Answers;
}

const fillScreenHandler: CommandHandlerFunction<FillScreen, Screen> =
  ({ queryBus }) =>
  async ({ aggregateRoot, command }) => {
    const { aggregateId, customerId, answers, slug } = command;
    const screen: ScreenProjection = await queryBus(viewScreenBySlugAndCustomerId({ slug, customerId }));

    if (!screen) {
      throw new Error("Can not fill the screen. Screen not found");
    }

    const validation: ValidationProjection = await queryBus(viewValidation({ answers, questions: [screen] }));

    if (validation?.length > 0) {
      throw new Error("Can not fill the screen. There are validation errors.");
    }

    return {
      ...aggregateRoot,
      customerId,
      answers: mapAnswersToInternationalSystem({ answers, questions: [screen] }),
      domainEvents: [screenFilled({ aggregateId })],
    };
  };

export type { Screen, Answers };
export { fillScreenHandler };
