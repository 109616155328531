import React, { FC, useCallback, useEffect, useRef } from "react";
import { ScrollView } from "react-native";
import { useNavigate, useParams } from "react-router-native";
import { Spinner } from "@lookiero/aurora";
import { QueryStatus } from "@lookiero/messaging-react";
import {
  AnswersProvider,
  AnswersValidationProvider,
  Body,
  FocusedInputProvider,
} from "@lookiero/sty-psp-quiz-style-profile-common-ui";
import { Layout as UiLayout, useScreenSize } from "@lookiero/sty-psp-ui";
import { MESSAGING_CONTEXT_ID } from "../../../delivery/baseBootstrap";
import { useViewStyleProfileAnswersByCustomerId } from "../../../projection/styleProfileAnswers/react/useViewStyleProfileAnswersByCustomerId";
import { useTrackScreenModalPageView } from "../../../tracking/useTrackScreenModalPageView";
import { useTrackScreenNavigationBack } from "../../../tracking/useTrackScreenNavigationBack";
import { useTrackScreenPageView } from "../../../tracking/useTrackScreenPageView";
import { StyleProfileHeader } from "../../components/templates/header/styleProfileHeader/StyleProfileHeader";
import { useScreen } from "../../hooks/useScreen";
import { useStaticInfo } from "../../hooks/useStaticInfo";
import { Routes } from "../../routing/routes";
import { style } from "./Screen.style";
import { QuestionForm } from "./components/questionForm/QuestionForm";

interface ScreenProps {
  readonly layout: UiLayout;
}

const Screen: FC<ScreenProps> = ({ layout: Layout }) => {
  const navigate = useNavigate();
  const { slug } = useParams();
  const {
    basePath,
    customer: { customerId, country, segment },
  } = useStaticInfo();
  const [screen, screenStatus] = useScreen();
  const [answers, answersStatus] = useViewStyleProfileAnswersByCustomerId({ customerId });

  const screenSize = useScreenSize();
  const scrollViewStyle = screenSize !== "S" && style.scrollView;

  const scrollRef = useRef<ScrollView>(null);
  useEffect(() => {
    scrollRef.current?.scrollTo({ y: 0, animated: false });
  }, [slug]);

  useTrackScreenPageView({
    country,
    segment,
    slug: screen?.metadata.slug,
    customerId,
  });
  const trackScreenNavigationBack = useTrackScreenNavigationBack({
    country,
    segment,
    customerId,
  });
  const trackSizeGuideModalPageView = useTrackScreenModalPageView({
    country,
    segment,
    slug: "size",
    customerId,
  });
  const trackSilhouetteGuideModalPageView = useTrackScreenModalPageView({
    country,
    segment,
    slug: "silhouette",
    customerId,
  });

  const handleOnBack = useCallback(() => {
    trackScreenNavigationBack({
      slug: screen?.metadata.slug,
      group: screen?.metadata.groupTranslationKey,
    });
    navigate(`${basePath}/${Routes.HOME}`, { state: { slug } });
  }, [
    basePath,
    navigate,
    screen?.metadata.groupTranslationKey,
    screen?.metadata.slug,
    slug,
    trackScreenNavigationBack,
  ]);

  const dependenciesLoaded = screenStatus !== QueryStatus.LOADING && screen && answersStatus !== QueryStatus.LOADING;

  if (!dependenciesLoaded) {
    return <Spinner testID="spinner" />;
  }

  return (
    <Layout
      header={<StyleProfileHeader onBack={handleOnBack} />}
      scrollRef={scrollRef}
      style={{ scrollView: scrollViewStyle }}
    >
      <Body style={{ column: style.bodyColumn }}>
        <AnswersProvider answers={answers} questions={[screen]}>
          <AnswersValidationProvider contextId={MESSAGING_CONTEXT_ID} scrollRef={scrollRef}>
            <FocusedInputProvider enabled={false} scrollRef={scrollRef}>
              <QuestionForm
                question={screen}
                onOpenSilhouetteGuide={trackSilhouetteGuideModalPageView}
                onOpenSizeGuide={trackSizeGuideModalPageView}
              />
            </FocusedInputProvider>
          </AnswersValidationProvider>
        </AnswersProvider>
      </Body>
    </Layout>
  );
};

export { Screen };
