import { useCallback } from "react";
import { Country } from "@lookiero/sty-psp-locale";
import { Segment } from "@lookiero/sty-psp-segment";
import { TrackingEventCategory, useEmitUserEvent } from "@lookiero/sty-psp-tracking";
import { PROJECT, TrackingEventName, UploadPhotoTakePhotoOptionSelectedTrackingEvent } from "./tracking";

interface TrackUploadPhotoTakePhotoOptionSelectedFunction {
  (): void;
}

interface UseTrackUploadPhotoTakePhotoOptionSelectedFunctionArgs {
  readonly country: Country;
  readonly segment: Segment;
  readonly customerId: string;
}

interface UseTrackUploadPhotoTakePhotoOptionSelectedFunction {
  (agrs: UseTrackUploadPhotoTakePhotoOptionSelectedFunctionArgs): TrackUploadPhotoTakePhotoOptionSelectedFunction;
}

const useTrackUploadPhotoTakePhotoOptionSelected: UseTrackUploadPhotoTakePhotoOptionSelectedFunction = ({
  country,
  segment,
  customerId,
}) => {
  const emitUserEvent = useEmitUserEvent<UploadPhotoTakePhotoOptionSelectedTrackingEvent>();

  const trackUploadPhotoTakePhotoOptionSelected: TrackUploadPhotoTakePhotoOptionSelectedFunction = useCallback(() => {
    emitUserEvent({
      event: TrackingEventName.UPLOAD_PHOTO_TAKE_PHOTO_OPTION_SELECTED,
      eventCategory: TrackingEventCategory.NAVIGATION,
      section: `${PROJECT}_photos`,
      store: country,
      segment,
      userId: customerId,
    });
  }, [country, emitUserEvent, customerId, segment]);

  return trackUploadPhotoTakePhotoOptionSelected;
};

export { useTrackUploadPhotoTakePhotoOptionSelected };
