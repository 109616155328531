import { HttpPostFunction } from "@lookiero/sty-psp-http";
import { ScreenProjection } from "@lookiero/sty-psp-quiz-style-profile-common-ui";
import {
  ScreenBySlugAndCustomerIdView,
  ViewScreenBySlugAndCustomerIdResult,
} from "../../../projection/screen/viewScreenBySlugAndCustomerId";

interface HttpScreenBySlugAndCustomerIdView extends ScreenBySlugAndCustomerIdView {}

interface HttpScreenBySlugAndCustomerIdViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpScreenBySlugAndCustomerIdViewFunction {
  (args: HttpScreenBySlugAndCustomerIdViewFunctionArgs): HttpScreenBySlugAndCustomerIdView;
}

const httpScreenBySlugAndCustomerIdView: HttpScreenBySlugAndCustomerIdViewFunction =
  ({ httpPost }) =>
  async ({ slug, customerId, experiment, signal }) =>
    await httpPost<ScreenProjection, ViewScreenBySlugAndCustomerIdResult>({
      endpoint: "/view-style-profile-screen-by-slug-and-customer-id",
      body: { slug, customerId, experiment },
      signal,
      result: {
        error: null,
        success: (response) => response,
      },
    });

export { httpScreenBySlugAndCustomerIdView };
