import { useLayoutEffect } from "react";
import { Country } from "@lookiero/sty-psp-locale";
import { Segment } from "@lookiero/sty-psp-segment";
import { TrackingEventCategory, useEmitUserEvent } from "@lookiero/sty-psp-tracking";
import { PROJECT, PageViewTrackingEvent, TrackingEventName } from "./tracking";

interface UseTrackScreenPageViewFunctionArgs {
  readonly slug: string | undefined;
  readonly country: Country;
  readonly segment: Segment;
  readonly customerId: string;
}

interface UseTrackScreenPageViewFunction {
  (agrs: UseTrackScreenPageViewFunctionArgs): void;
}

const useTrackScreenPageView: UseTrackScreenPageViewFunction = ({ slug, country, segment, customerId }) => {
  const emitUserEvent = useEmitUserEvent<PageViewTrackingEvent>();

  useLayoutEffect(() => {
    if (!slug) {
      return;
    }

    emitUserEvent({
      event: TrackingEventName.PAGEVIEW,
      eventCategory: TrackingEventCategory.NAVIGATION,
      section: `${PROJECT}_${slug}`,
      store: country,
      segment,
      userId: customerId,
    });
  }, [country, customerId, emitUserEvent, segment, slug]);
};

export { useTrackScreenPageView };
