import { AnswersProjection } from "@lookiero/sty-psp-quiz-style-profile-common-ui";
import { BaseTrackingEvent, TrackingEventCategory } from "@lookiero/sty-psp-tracking";

const PROJECT = "style_profile";

enum TrackingPage {
  NOT_FOUND = "not_found",
  STYLE = "style",
}

enum TrackingEventName {
  PAGEVIEW = "pageview",
  NAVIGATION_BACK = "navigationBack",
  FILL_STYLE_PROFILE = "styleProfileFilled",
  UPLOAD_PHOTO_UPLOAD_ICON_SELECTED = "uploadPhotoUploadIconSelected",
  UPLOAD_PHOTO_OPTIONS_MODAL_VIEW = "uploadPhotoOptionsModalView",
  UPLOAD_PHOTO_TAKE_PHOTO_OPTION_SELECTED = "uploadPhotoTakePhotoOptionSelected",
  UPLOAD_PHOTO_CHOOSE_LIBRARY_OPTION_SELECTED = "uploadPhotoChooseLibraryOptionSelected",
  UPLOAD_PHOTO_ERROR = "uploadPhotoError",
  IMAGE_UPLOADED = "imageUploaded",
  UPLOAD_PHOTO_OPTIONS_MODAL_CLOSE = "uploadPhotoOptionsModalClose",
  UPLOAD_PHOTO_CHOOSE_LIBRARY_OPTION_CANCELED = "uploadPhotoChooseLibraryOptionCanceled",
  UPLOAD_PHOTO_TAKE_PHOTO_OPTION_CANCELED = "uploadPhotoTakePhotoOptionCanceled",
  QUESTION_ERROR = "questionError",
}

interface StyleProfileBaseTrackingEvent<
  EN extends TrackingEventName,
  EC extends TrackingEventCategory = TrackingEventCategory.NAVIGATION,
> extends BaseTrackingEvent<EN, EC> {
  readonly userId: string;
}

type PageViewTrackingEvent = StyleProfileBaseTrackingEvent<TrackingEventName.PAGEVIEW>;

interface NavigationBackTrackingEvent extends StyleProfileBaseTrackingEvent<TrackingEventName.NAVIGATION_BACK> {
  readonly group?: string;
}

interface FillScreenTrackingEvent extends StyleProfileBaseTrackingEvent<TrackingEventName.FILL_STYLE_PROFILE> {
  readonly group: string;
  readonly question: string;
  readonly answers: AnswersProjection;
}

type UploadPhotoUploadIconSelectedTrackingEvent =
  StyleProfileBaseTrackingEvent<TrackingEventName.UPLOAD_PHOTO_UPLOAD_ICON_SELECTED>;

type UploadPhotoOptionsModalViewTrackingEvent =
  StyleProfileBaseTrackingEvent<TrackingEventName.UPLOAD_PHOTO_OPTIONS_MODAL_VIEW>;

type UploadPhotoTakePhotoOptionSelectedTrackingEvent =
  StyleProfileBaseTrackingEvent<TrackingEventName.UPLOAD_PHOTO_TAKE_PHOTO_OPTION_SELECTED>;

type UploadPhotoChooseLibraryOptionSelectedTrackingEvent =
  StyleProfileBaseTrackingEvent<TrackingEventName.UPLOAD_PHOTO_CHOOSE_LIBRARY_OPTION_SELECTED>;

type UploadPhotoErrorTrackingEvent = StyleProfileBaseTrackingEvent<TrackingEventName.UPLOAD_PHOTO_ERROR>;

type ImageUploadedTrackingEvent = StyleProfileBaseTrackingEvent<TrackingEventName.IMAGE_UPLOADED>;

type UploadPhotoOptionsModalCloseTrackingEvent =
  StyleProfileBaseTrackingEvent<TrackingEventName.UPLOAD_PHOTO_OPTIONS_MODAL_CLOSE>;

type UploadPhotoChooseLibraryOptionCanceledTrackingEvent =
  StyleProfileBaseTrackingEvent<TrackingEventName.UPLOAD_PHOTO_CHOOSE_LIBRARY_OPTION_CANCELED>;

type UploadPhotoTakePhotoOptionCanceledTrackingEvent =
  StyleProfileBaseTrackingEvent<TrackingEventName.UPLOAD_PHOTO_TAKE_PHOTO_OPTION_CANCELED>;

interface QuestionErrorTrackingEvent extends StyleProfileBaseTrackingEvent<TrackingEventName.QUESTION_ERROR> {
  readonly group: string;
  readonly errorType: "format" | "mandatory";
}

export { PROJECT, TrackingPage, TrackingEventName };
export type {
  PageViewTrackingEvent,
  NavigationBackTrackingEvent,
  FillScreenTrackingEvent,
  UploadPhotoUploadIconSelectedTrackingEvent,
  UploadPhotoOptionsModalViewTrackingEvent,
  UploadPhotoTakePhotoOptionSelectedTrackingEvent,
  UploadPhotoErrorTrackingEvent,
  UploadPhotoChooseLibraryOptionSelectedTrackingEvent,
  ImageUploadedTrackingEvent,
  UploadPhotoOptionsModalCloseTrackingEvent,
  UploadPhotoChooseLibraryOptionCanceledTrackingEvent,
  UploadPhotoTakePhotoOptionCanceledTrackingEvent,
  QuestionErrorTrackingEvent,
};
