import { DomainEvent, MessageName } from "@lookiero/messaging";
import { useQuery, UseQueryFunctionResult } from "@lookiero/messaging-react";
import { AnswersProjection } from "@lookiero/sty-psp-quiz-style-profile-common-ui";
import { isScreenFilled } from "../../../../domain/screen/model/screenFilled";
import { viewStyleProfileAnswersByCustomerId } from "../../../../projection/styleProfileAnswers/viewStyleProfileAnswersByCustomerId";
import { MESSAGING_CONTEXT_ID } from "../../../delivery/baseBootstrap";

interface UseViewStyleProfileAnswersByCustomerIdFunctionArgs {
  readonly customerId: string;
}

interface UseViewStyleProfileAnswersByCustomerIdFunction {
  (args: UseViewStyleProfileAnswersByCustomerIdFunctionArgs): UseQueryFunctionResult<AnswersProjection>;
}

const shouldInvalidate = (event: DomainEvent<MessageName>) => isScreenFilled(event);

const useViewStyleProfileAnswersByCustomerId: UseViewStyleProfileAnswersByCustomerIdFunction = ({ customerId }) =>
  useQuery({
    query: viewStyleProfileAnswersByCustomerId({ customerId }),
    contextId: MESSAGING_CONTEXT_ID,
    invalidation: shouldInvalidate,
    options: {
      staleTime: Infinity,
      retry: false,
      refetchOnWindowFocus: false,
    },
  });

export { useViewStyleProfileAnswersByCustomerId };
