import React, { FC } from "react";
import { View } from "react-native";
import { ALIGN, Text } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import { ButtonIcon } from "@lookiero/sty-psp-ui";
import { I18nMessages, PREFIX } from "../../../../i18n/i18n";
import { Header } from "../Header";
import { style } from "./StyleProfileHeader.style";

interface StyleProfileHeaderProps {
  readonly onBack?: () => void;
}
const StyleProfileHeader: FC<StyleProfileHeaderProps> = ({ onBack }) => {
  const titleText = useI18nMessage({ id: I18nMessages.HEADER_TITLE, prefix: PREFIX });

  return (
    <Header testID="style-profile-header">
      <ButtonIcon name="arrow_left" testID="back-button-icon" onPress={onBack} />
      <Text align={ALIGN.CENTER} level={3} action>
        {titleText}
      </Text>
      <View style={style.buttonIconPlaceholder} />
    </Header>
  );
};

export { StyleProfileHeader };
