import { useCallback } from "react";
import { Country } from "@lookiero/sty-psp-locale";
import { Segment } from "@lookiero/sty-psp-segment";
import { TrackingEventCategory, useEmitUserEvent } from "@lookiero/sty-psp-tracking";
import { PROJECT, TrackingEventName, UploadPhotoOptionsModalCloseTrackingEvent } from "./tracking";

interface TrackUploadPhotoOptionsModalCloseFunction {
  (): void;
}

interface UseTrackUploadPhotoOptionsModalCloseFunctionArgs {
  readonly country: Country;
  readonly segment: Segment;
  readonly customerId: string;
}

interface UseTrackUploadPhotoOptionsModalCloseFunction {
  (agrs: UseTrackUploadPhotoOptionsModalCloseFunctionArgs): TrackUploadPhotoOptionsModalCloseFunction;
}

const useTrackUploadPhotoOptionsModalClose: UseTrackUploadPhotoOptionsModalCloseFunction = ({
  country,
  segment,
  customerId,
}) => {
  const emitUserEvent = useEmitUserEvent<UploadPhotoOptionsModalCloseTrackingEvent>();

  const trackUploadPhotoOptionsModalClose: TrackUploadPhotoOptionsModalCloseFunction = useCallback(() => {
    emitUserEvent({
      event: TrackingEventName.UPLOAD_PHOTO_OPTIONS_MODAL_CLOSE,
      eventCategory: TrackingEventCategory.NAVIGATION,
      section: `${PROJECT}_photos`,
      store: country,
      segment,
      userId: customerId,
    });
  }, [country, emitUserEvent, customerId, segment]);

  return trackUploadPhotoOptionsModalClose;
};

export { useTrackUploadPhotoOptionsModalClose };
