import { useCallback } from "react";
import { Country } from "@lookiero/sty-psp-locale";
import { Segment } from "@lookiero/sty-psp-segment";
import { TrackingEventCategory, useEmitUserEvent } from "@lookiero/sty-psp-tracking";
import { PROJECT, PageViewTrackingEvent, TrackingEventName } from "./tracking";

interface TrackPageViewFunction {
  (): void;
}

interface UseTrackScreenModalPageViewFunctionArgs {
  readonly slug: string;
  readonly country: Country;
  readonly segment: Segment;
  readonly customerId: string;
}

interface UseTrackScreenModalPageViewFunction {
  (agrs: UseTrackScreenModalPageViewFunctionArgs): TrackPageViewFunction;
}

const useTrackScreenModalPageView: UseTrackScreenModalPageViewFunction = ({ slug, country, segment, customerId }) => {
  const emitUserEvent = useEmitUserEvent<PageViewTrackingEvent>();

  const trackPageView: TrackPageViewFunction = useCallback(() => {
    emitUserEvent({
      event: TrackingEventName.PAGEVIEW,
      eventCategory: TrackingEventCategory.NAVIGATION,
      section: `${PROJECT}_${slug}_modal`,
      store: country,
      segment,
      userId: customerId,
    });
  }, [country, customerId, emitUserEvent, segment, slug]);

  return trackPageView;
};

export { useTrackScreenModalPageView };
