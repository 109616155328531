import React, { FC, memo } from "react";
import { Navigate, Outlet, useRoutes as reactRouterUseRoutes } from "react-router-native";
import { Spinner } from "@lookiero/aurora";
import { I18n } from "@lookiero/i18n-react";
import { LookAndLike } from "@lookiero/look-and-like";
import { Kameleoon } from "@lookiero/sty-psp-ab-testing";
import { Locale } from "@lookiero/sty-psp-locale";
import { Layout } from "@lookiero/sty-psp-ui";
import { Customer } from "../../../projection/customer/customer";
import { KameleoonEnvironment } from "../../ab-testing/kameleoonEnvironment";
import { ScreenProvider } from "../hooks/useScreen";
import { StaticInfoProvider } from "../hooks/useStaticInfo";
import { App } from "../views/App";
import { Home } from "../views/home/Home";
import { NotFound } from "../views/notFound/NotFound";
import { Screen } from "../views/screen/Screen";
import { Routes } from "./routes";

interface RoutingProps {
  readonly apiUrl: () => string;
  readonly getAuthToken: () => Promise<string>;
  readonly basePath?: string;
  readonly customer: Customer | undefined;
  readonly I18n: I18n;
  readonly kameleoon: KameleoonEnvironment;
  readonly layout: Layout;
  readonly locale: Locale;
  readonly iOSInAppBrowserVideoAd: boolean;
  readonly iOSInAppBrowserVideoAdBannerHeight: number;
  readonly lookAndLike: LookAndLike;
  readonly onBack: () => void;
  readonly onI18nError?: (err: Error) => void;
  readonly useRoutes: typeof reactRouterUseRoutes;
}

const Routing: FC<RoutingProps> = ({
  apiUrl,
  getAuthToken,
  basePath = "",
  customer,
  I18n,
  kameleoon,
  layout,
  locale,
  iOSInAppBrowserVideoAd,
  iOSInAppBrowserVideoAdBannerHeight,
  lookAndLike,
  onBack,
  onI18nError,
  useRoutes = reactRouterUseRoutes,
}) => {
  return useRoutes([
    {
      path: "",
      element: (
        <StaticInfoProvider
          apiUrl={apiUrl}
          basePath={basePath}
          customer={customer as Customer}
          getAuthToken={getAuthToken}
          kameleoon={kameleoon}
        >
          <I18n loader={<Spinner />} locale={locale} onError={onI18nError}>
            <Kameleoon loader={<Spinner />} siteCode={kameleoon.siteCode}>
              <ScreenProvider>
                <App
                  iOSInAppBrowserVideoAd={iOSInAppBrowserVideoAd}
                  iOSInAppBrowserVideoAdBannerHeight={iOSInAppBrowserVideoAdBannerHeight}
                >
                  <Outlet />
                </App>
              </ScreenProvider>
            </Kameleoon>
          </I18n>
        </StaticInfoProvider>
      ),
      children: [
        {
          path: Routes.HOME,
          element: <Home layout={layout} lookAndLike={lookAndLike} onBack={onBack} />,
        },
        {
          path: Routes.SCREEN,
          element: <Screen layout={layout} />,
        },
        {
          path: Routes.NOT_FOUND,
          element: <NotFound layout={layout} />,
        },
        {
          path: "*",
          element: <Navigate to={`${Routes.HOME}`} replace />,
        },
      ],
    },
  ]);
};

/**
 * Provided useRoutes is not stable (when integrated with Sentry) as it's rendering a different component tree.
 *
 * https://github.com/getsentry/sentry-javascript/blob/master/packages/react/src/reactrouterv6.tsx#L221
 * (SentryRoutes is a new component after each re-render)
 */
const MemoizedRouting = memo(Routing);
export { MemoizedRouting as Routing };
