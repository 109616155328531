import { useCallback } from "react";
import { Country } from "@lookiero/sty-psp-locale";
import { AnswersProjection } from "@lookiero/sty-psp-quiz-style-profile-common-ui";
import { Segment } from "@lookiero/sty-psp-segment";
import { TrackingEventCategory, useEmitUserEvent } from "@lookiero/sty-psp-tracking";
import { FillScreenTrackingEvent, PROJECT, TrackingEventName } from "./tracking";

interface TrackFillScreenFunctionArgs {
  readonly group: string;
  readonly question: string;
  readonly answers: AnswersProjection;
}
interface TrackFillScreenFunction {
  (args: TrackFillScreenFunctionArgs): void;
}

interface UseTrackFillScreenFunctionArgs {
  readonly slug: string;
  readonly country: Country;
  readonly segment: Segment;
  readonly customerId: string;
}

interface UseTrackFillScreenFunction {
  (agrs: UseTrackFillScreenFunctionArgs): TrackFillScreenFunction;
}

const useTrackFillScreen: UseTrackFillScreenFunction = ({ slug, country, segment, customerId }) => {
  const emitUserEvent = useEmitUserEvent<FillScreenTrackingEvent>();

  const trackFillScreen: TrackFillScreenFunction = useCallback(
    ({ group, question, answers }) => {
      emitUserEvent({
        event: TrackingEventName.FILL_STYLE_PROFILE,
        eventCategory: TrackingEventCategory.NAVIGATION,
        section: `${PROJECT}_${slug}`,
        store: country,
        segment,
        group,
        question,
        answers,
        userId: customerId,
      });
    },
    [country, customerId, emitUserEvent, segment, slug],
  );

  return trackFillScreen;
};

export { useTrackFillScreen };
