import { useCallback } from "react";
import invariant from "tiny-invariant";
import { v4 as uuid } from "uuid";
import { CommandStatus, useCommand } from "@lookiero/messaging-react";
import { Logger } from "@lookiero/sty-psp-logging";
import { NotificationLevel, useCreateToastNotification } from "@lookiero/sty-psp-notifications";
import { AnswersProjection } from "@lookiero/sty-psp-quiz-style-profile-common-ui";
import { fillScreen } from "../../../../domain/screen/command/fillScreen";
import { MESSAGING_CONTEXT_ID } from "../../../delivery/baseBootstrap";
import { I18nMessages, PREFIX } from "../../../ui/i18n/i18n";

interface FillScreenFunctionArgs {
  readonly answers: AnswersProjection;
}

interface FillScreenFunction {
  (args: FillScreenFunctionArgs): Promise<void> | undefined;
}

interface UseFillScreenArgs {
  readonly customerId: string;
  readonly slug: string;
  readonly logger: Logger;
}

type UseFillScreenResult = [fill: FillScreenFunction, status: CommandStatus];

interface UseFillScreen {
  (args: UseFillScreenArgs): UseFillScreenResult;
}

const useFillScreen: UseFillScreen = ({ customerId, slug, logger }) => {
  const [commandBus, status] = useCommand({ contextId: MESSAGING_CONTEXT_ID });
  const [createNotification] = useCreateToastNotification({ contextId: MESSAGING_CONTEXT_ID, logger });

  const fill: FillScreenFunction = useCallback(
    async ({ answers }) => {
      invariant(customerId, "customerId must be defined in order to fill the screen");

      try {
        // There is no Screen AggRoot on the backend, so provided id does not matter.
        await commandBus(fillScreen({ aggregateId: uuid(), customerId, slug, answers }));

        createNotification({
          bodyI18nKey: `${PREFIX}${I18nMessages.SAVE_CONFIRMATION_TOAST}`,
          level: NotificationLevel.SUCCESS,
        });
      } catch (error) {
        createNotification({
          bodyI18nKey: I18nMessages.TOAST_GENERIC_ERROR,
          level: NotificationLevel.ERROR,
        });
        logger.captureException(error as Error);
      }
    },
    [commandBus, createNotification, customerId, logger, slug],
  );

  return [fill, status];
};

export { useFillScreen };
