import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { AnswersProjection } from "@lookiero/sty-psp-quiz-style-profile-common-ui";

const VIEW_STYLE_PROFILE_ANSWERS_BY_CUSTOMER_ID = "view_style_profile_answers_by_customer_id";

interface ViewStyleProfileAnswersByCustomerIdPayload {
  readonly customerId: string;
}

interface ViewStyleProfileAnswersByCustomerId
  extends Query<typeof VIEW_STYLE_PROFILE_ANSWERS_BY_CUSTOMER_ID>,
    ViewStyleProfileAnswersByCustomerIdPayload {}

interface ViewStyleProfileAnswersByCustomerIdFunction {
  (payload: ViewStyleProfileAnswersByCustomerIdPayload): ViewStyleProfileAnswersByCustomerId;
}

const viewStyleProfileAnswersByCustomerId: ViewStyleProfileAnswersByCustomerIdFunction = (payload) => ({
  ...query({ name: VIEW_STYLE_PROFILE_ANSWERS_BY_CUSTOMER_ID }),
  ...payload,
});

type ViewStyleProfileAnswersByCustomerIdResult = AnswersProjection | null;

interface StyleProfileAnswersByCustomerIdViewArgs extends CancelableQueryViewArgs {
  readonly customerId: string;
}

interface StyleProfileAnswersByCustomerIdView {
  (args: StyleProfileAnswersByCustomerIdViewArgs): Promise<ViewStyleProfileAnswersByCustomerIdResult>;
}

interface ViewStyleProfileAnswersByCustomerIdHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: StyleProfileAnswersByCustomerIdView;
}

const viewStyleProfileAnswersByCustomerIdHandler: QueryHandlerFunction<
  ViewStyleProfileAnswersByCustomerId,
  ViewStyleProfileAnswersByCustomerIdResult,
  ViewStyleProfileAnswersByCustomerIdHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ customerId }) =>
    view({ customerId, signal });

export type { StyleProfileAnswersByCustomerIdView, ViewStyleProfileAnswersByCustomerIdResult };
export {
  VIEW_STYLE_PROFILE_ANSWERS_BY_CUSTOMER_ID,
  viewStyleProfileAnswersByCustomerId,
  viewStyleProfileAnswersByCustomerIdHandler,
};
