import { isScreenFilled } from "../../../../domain/screen/model/screenFilled";
import { HttpScreensSaveFunction } from "./httpScreens";

interface HttpScreensFillFunction extends HttpScreensSaveFunction {}

const httpScreensFill: HttpScreensFillFunction =
  ({ httpPost }) =>
  async ({ customerId, answers, domainEvents }) => {
    const screenFilled = domainEvents.find(isScreenFilled);

    if (!screenFilled) {
      return;
    }

    await httpPost({
      endpoint: "/fill-style-profile-answers",
      body: {
        customerId,
        answers,
      },
    });
  };

export { httpScreensFill };
