import { useCallback } from "react";
import { Country } from "@lookiero/sty-psp-locale";
import { Segment } from "@lookiero/sty-psp-segment";
import { TrackingEventCategory, useEmitUserEvent } from "@lookiero/sty-psp-tracking";
import { PROJECT, TrackingEventName, UploadPhotoUploadIconSelectedTrackingEvent } from "./tracking";

interface TrackUploadPhotoUploadIconSelectedFunction {
  (): void;
}

interface UseTrackUploadPhotoUploadIconSelectedFunctionArgs {
  readonly country: Country;
  readonly segment: Segment;
  readonly customerId: string;
}

interface UseTrackUploadPhotoUploadIconSelectedFunction {
  (agrs: UseTrackUploadPhotoUploadIconSelectedFunctionArgs): TrackUploadPhotoUploadIconSelectedFunction;
}

const useTrackUploadPhotoUploadIconSelected: UseTrackUploadPhotoUploadIconSelectedFunction = ({
  country,
  segment,
  customerId,
}) => {
  const emitUserEvent = useEmitUserEvent<UploadPhotoUploadIconSelectedTrackingEvent>();

  const trackUploadPhotoUploadIconSelected: TrackUploadPhotoUploadIconSelectedFunction = useCallback(() => {
    emitUserEvent({
      event: TrackingEventName.UPLOAD_PHOTO_UPLOAD_ICON_SELECTED,
      eventCategory: TrackingEventCategory.NAVIGATION,
      section: `${PROJECT}_photos`,
      store: country,
      segment,
      userId: customerId,
    });
  }, [country, emitUserEvent, customerId, segment]);

  return trackUploadPhotoUploadIconSelected;
};

export { useTrackUploadPhotoUploadIconSelected };
