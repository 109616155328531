import { useFonts } from "expo-font";
import React, { FC } from "react";
import { Platform } from "react-native";
import "react-native-get-random-values";
import { Navigate, Route, Routes } from "react-router-native";
import { Aurora } from "@lookiero/aurora";
import { EndpointFunction } from "@lookiero/i18n";
import { i18n } from "@lookiero/i18n-react";
import { bootstrap as lookAndLikeBootstrap } from "@lookiero/look-and-like";
import { fetchTranslations, translationExternalEndpoint } from "@lookiero/sty-psp-i18n";
import { Country, Locale } from "@lookiero/sty-psp-locale";
import { SentryEnvironment } from "@lookiero/sty-psp-logging";
import { Segment } from "@lookiero/sty-psp-segment";
import { DummyLayout } from "@lookiero/sty-psp-ui";
import { KameleoonEnvironment } from "./infrastructure/ab-testing/kameleoonEnvironment";
// import { bootstrap as styleProfileBootstrap } from "./infrastructure/delivery/bootstrap.mock";
import { bootstrap as styleProfileBootstrap } from "./infrastructure/delivery/bootstrap";
import { root } from "./infrastructure/ui/Root";
import { Router } from "./infrastructure/ui/routing/router/Router";
import { Customer } from "./projection/customer/customer";
import { VERSION } from "./version";

const locale: Locale = Locale.es_ES;

const customer: Customer = {
  customerId: "0ede276c-4d51-48b0-9dbc-482f88cbfc77",
  country: Country.ES,
  segment: Segment.WOMEN,
};

const sentryConfig: SentryEnvironment = {
  publicKey: "bf9ded71e97254c526526bf5cc878aa1",
  release: VERSION,
  project: "4506121843048448",
  environment: `${Platform.OS}-EXPO`,
};

const apiUrl =
  Platform.OS !== "web"
    ? "https://web2.sp.dev.aws.lookiero.es/style-profile/api"
    : __DEV__
      ? "/local-to-dev"
      : "/style-profile/api";
const lookAndLikeApiUrl = Platform.OS !== "web" ? "https://web2.sp.dev.aws.lookiero.es/lookandlike" : "/lookandlike";
const authToken =
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOjUyNTgzODQsImV4cCI6MTcyMzY0MTc3NCwiZGlzcGxheU5hbWUiOiJtYWlsLmRldithLnNhYXZlZHJhI2xvb2tpZXJvLmNvbUBsb29raWVyby5jb20iLCJjb3VudHJ5X2NvZGUiOiJFUyIsImFjY2Vzc1ZpYSI6ImVtYWlsIiwidXVpZCI6IjBlZGUyNzZjLTRkNTEtNDhiMC05ZGJjLTQ4MmY4OGNiZmM3NyIsImlhdCI6MTcyMzYxMjk3NH0.x7E9b7DWVZjVAHJO_L36f7ZMxe_8N2Pjr8xHPjP1qF0";
const getAuthToken = () => Promise.resolve(authToken);

const externalTranslationsUrl =
  Platform.OS !== "web"
    ? "https://backend-for-user.dev.envs.lookiero.tech/api/v2/translations"
    : __DEV__
      ? "/local-to-external-i18n"
      : "/external-i18n";
const translations: EndpointFunction[] = [
  (locale) =>
    translationExternalEndpoint({
      translationsUrl: externalTranslationsUrl,
      projects: [["user-area-front"], ["style-profile"], ["look-and-like"]],
    })(locale),
];

const kameleoonConfig: KameleoonEnvironment = {
  siteCode: "aplm4v3ckn",
  experiment: {
    id: "262141",
    variations: {
      v1: "1004339",
    },
  },
};

const { Component: Messaging } = styleProfileBootstrap({ apiUrl: () => apiUrl, getAuthToken });
const I18n = i18n({
  fetchTranslation: fetchTranslations({ translations }),
  contextId: "StyleProfileI18n",
});
const Root = root({
  Messaging,
  I18n,
  development: false,
  sentry: () => sentryConfig,
  kameleoon: () => kameleoonConfig,
})({
  customerId: customer.customerId,
});

const { root: lookAndLikeRoot } = lookAndLikeBootstrap({
  apiUrl: () => lookAndLikeApiUrl,
  getAuthToken,
  sentry: () => sentryConfig,
  translations,
});
const LookAndLikeRoot = lookAndLikeRoot({
  customerId: customer.customerId,
});

const ExpoRoot: FC = () => {
  const [fontsLoaded] = useFonts({
    ["AreaInktrap-Semibold"]: require("@lookiero/aurora-fonts/AreaInktrap-Semibold.otf"),
    ["AreaNormal-Semibold"]: require("@lookiero/aurora-fonts/AreaNormal-Semibold.otf"),
    ["AreaNormal-Extrabold"]: require("@lookiero/aurora-fonts/AreaNormal-Extrabold.otf"),
    auroraicons: require("@lookiero/aurora-iconfont/dist/auroraicons.ttf"),
  });

  return fontsLoaded ? (
    <Aurora>
      <Router>
        <Routes>
          <Route
            path="/style-profile/*"
            element={
              <Root
                apiUrl={() => apiUrl}
                basePath="/style-profile"
                customer={customer}
                getAuthToken={getAuthToken}
                layout={DummyLayout}
                locale={locale}
                lookAndLike={(props) => <LookAndLikeRoot locale={locale} {...props} />}
                onBack={() => void 0}
              />
            }
          />

          <Route element={<Navigate to="/style-profile" replace />} path="*" />
        </Routes>
      </Router>
    </Aurora>
  ) : null;
};

export { ExpoRoot };
