import { RepositoryGetFunctionArgs, RepositorySaveFunctionArgs } from "@lookiero/messaging";
import { HttpPostFunction } from "@lookiero/sty-psp-http";
import { ScreensGetFunction, ScreensSaveFunction } from "../../../../domain/screen/model/screens";
import { httpScreensFill } from "./httpScreensFill";

interface HttpScreensGetFunctionArgs extends RepositoryGetFunctionArgs {}

interface HttpScreensGetFunction extends ScreensGetFunction<HttpScreensGetFunctionArgs> {}

const getScreen: HttpScreensGetFunction = () => async () => {
  throw new Error("Screen not found");
};

interface HttpScreensSaveFunctionArgs extends RepositorySaveFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpScreensSaveFunction extends ScreensSaveFunction<HttpScreensSaveFunctionArgs> {}

const saveScreen: HttpScreensSaveFunction =
  ({ httpPost }) =>
  async (aggregateRoot) =>
    await httpScreensFill({ httpPost })(aggregateRoot);

export type { HttpScreensSaveFunction };
export { getScreen, saveScreen };
