import React, { FC, useEffect, useCallback, RefObject, useRef, createRef } from "react";
import { View, Pressable } from "react-native";
import { useNavigate, generatePath } from "react-router-native";
import { Accordion, AccordionContainer, Divider, Text, COLOR } from "@lookiero/aurora";
import { useIntl } from "@lookiero/i18n-react";
import { StyleProfileMenu } from "../../../../../../projection/styleProfileMenu/styleProfileMenu";
import { Routes } from "../../../../routing/routes";
import { style } from "./Menu.style";

export const ACCORDION_GAP = 64;

interface OnItemSelectedFunctionArgs {
  readonly ref: RefObject<View>;
}
interface OnItemSelectedFunction {
  (args: OnItemSelectedFunctionArgs): void;
}

interface MenuProps {
  readonly menu: StyleProfileMenu;
  readonly activeIndex?: number;
  readonly onItemSelected: OnItemSelectedFunction;
}

const Menu: FC<MenuProps> = ({ menu, activeIndex, onItemSelected }) => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const menuItemsRef = useRef(menu.map(() => createRef<View>()));
  const handleOnPress = useCallback(
    (accordionIndex: number) => onItemSelected({ ref: menuItemsRef.current[accordionIndex] as RefObject<View> }),
    [onItemSelected],
  );
  useEffect(() => {
    if (activeIndex === undefined || !menuItemsRef.current[activeIndex]) {
      return;
    }

    onItemSelected({ ref: menuItemsRef.current[activeIndex] as RefObject<View> });
  }, [activeIndex, onItemSelected]);

  return (
    <View testID="style-profile-menu">
      <AccordionContainer active={activeIndex} wide onPress={handleOnPress}>
        {menu.map((menuItem, index) => (
          <Accordion
            key={menuItem.translationKey}
            ref={menuItemsRef.current[index]}
            testID={menuItem.translationKey}
            text={formatMessage({ id: menuItem.descriptionTranslationKey })}
            title={formatMessage({ id: menuItem.translationKey })}
          >
            {menuItem.screens.map(({ slug, translationKey }) => (
              <Pressable
                key={translationKey}
                testID={translationKey}
                onPress={() => navigate(generatePath(Routes.SCREEN, { slug }))}
              >
                <Text as="p" level={2} style={style.screenText}>
                  {formatMessage({ id: translationKey })}
                </Text>
                <Divider color={COLOR.BORDER_INTERACTIVE} />
              </Pressable>
            ))}
          </Accordion>
        ))}
      </AccordionContainer>
    </View>
  );
};

export { Menu };
