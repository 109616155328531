const DATE_FORMAT_PREFIX = "date_format.";
const PREFIX = "style_profile.";

enum I18nMessages {
  HEADER_TITLE = "header.title",
  HOME_TITLE = "main_page.title",
  HOME_DESCRIPTION = "main_page.subtitle",
  SOCIAL_SUBTITLE = "social.subtitle",
  IMAGE_PICKER_MODAL_TITLE = "image_picker_modal.title",
  IMAGE_PICKER_MODAL_TAKE_PHOTO_BUTTON = "image_picker_modal.take_photo_button",
  IMAGE_PICKER_MODAL_CHOOSE_FROM_LIBRARY_BUTTON = "image_picker_modal.choose_from_library_button",
  UPLOAD_IMAGE_TOAST_GENERIC_ERROR = "upload_image.toast.generic_error",
  SAVE_CONFIRMATION_TOAST = "save_confirmation.toast",
  TOAST_GENERIC_ERROR = "toast.generic.error",
}

export { I18nMessages, DATE_FORMAT_PREFIX, PREFIX };
