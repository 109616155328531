interface ReadFileAsDataURLFunctionArgs {
  readonly file: Blob;
}
interface ReadFileAsDataURLFunction {
  (args: ReadFileAsDataURLFunctionArgs): Promise<string | undefined>;
}
const readFileAsDataURL: ReadFileAsDataURLFunction = async ({ file }) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      if (reader.result) {
        resolve(reader.result.toString());
      }

      reject();
    };
  });

export { readFileAsDataURL };
