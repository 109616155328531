import { command, Command } from "@lookiero/messaging";
import { Answers } from "../model/screen";

const FILL_SCREEN = "fill_screen";

interface FillScreenPayload {
  readonly aggregateId: string;
  readonly customerId: string;
  readonly slug: string;
  readonly answers: Answers;
}

interface FillScreen extends Command<typeof FILL_SCREEN>, FillScreenPayload {}

interface FillScreenFunction {
  (payload: FillScreenPayload): FillScreen;
}

const fillScreen: FillScreenFunction = ({ aggregateId, ...payload }) => ({
  ...command({ aggregateId, name: FILL_SCREEN }),
  ...payload,
});

export type { FillScreen };
export { FILL_SCREEN, fillScreen };
