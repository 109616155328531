import { useLayoutEffect } from "react";
import { Country } from "@lookiero/sty-psp-locale";
import { Segment } from "@lookiero/sty-psp-segment";
import { TrackingEventCategory, useEmitUserEvent } from "@lookiero/sty-psp-tracking";
import { PROJECT, PageViewTrackingEvent, TrackingEventName, TrackingPage } from "./tracking";

interface UseTrackPageViewFunctionArgs {
  readonly page: TrackingPage;
  readonly country: Country;
  readonly segment: Segment;
  readonly customerId: string;
}

interface UseTrackPageViewFunction {
  (agrs: UseTrackPageViewFunctionArgs): void;
}

const useTrackPageView: UseTrackPageViewFunction = ({ page, country, segment, customerId }) => {
  const emitUserEvent = useEmitUserEvent<PageViewTrackingEvent>();

  useLayoutEffect(() => {
    emitUserEvent({
      event: TrackingEventName.PAGEVIEW,
      eventCategory: TrackingEventCategory.NAVIGATION,
      section: `${PROJECT}_${page}`,
      store: country,
      segment,
      userId: customerId,
    });
  }, [country, customerId, emitUserEvent, page, segment]);
};

export { useTrackPageView };
