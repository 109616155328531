import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const { space6 } = theme();

const style = StyleSheet.create({
  screenText: {
    marginVertical: space6,
  },
});

export { style };
