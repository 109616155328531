/* eslint-disable react/prop-types */
import React, { useCallback, useRef } from "react";
import { View } from "react-native";
import { useI18nMessage } from "@lookiero/i18n-react";
import {
  QuestionItem,
  QuestionType,
  StyleProfileSocialIcon,
  useQuestionValidationError,
  useAnswerForId,
  useAnswersValidation,
} from "@lookiero/sty-psp-quiz-style-profile-common-ui";
import { IconName, InputField, TextInput } from "@lookiero/sty-psp-ui";

const SOCIAL_NETWORKS_INPUT_ICON: Record<StyleProfileSocialIcon, IconName> = {
  ["style_profile.icon.instagram"]: "social_instagram",
  ["style_profile.icon.pinterest"]: "social_pinterest",
};

const SocialNetworksInputQuestionItem: QuestionItem<QuestionType.SOCIAL_NETWORKS_INPUT> = ({ question }) => {
  const label = useI18nMessage({ id: question.translationKey });
  const hint = useI18nMessage({ id: question.metadata.hint });

  const { answer, onChange } = useAnswerForId({ id: question.id });
  const selectedAnswer = (answer && answer[0]) || "";

  const ref = useRef(null);
  const validationError = useQuestionValidationError({ questionId: question.id, ref });

  const { validateMaxAnswers } = useAnswersValidation();

  const handleOnChange = useCallback(
    (value: string | undefined) => {
      onChange({ questionId: question.id, answer: value ? `${value}` : "" });
      validateMaxAnswers();
    },
    [onChange, question.id, validateMaxAnswers],
  );

  const icon = SOCIAL_NETWORKS_INPUT_ICON[question.metadata.icon as StyleProfileSocialIcon];

  return (
    <View ref={ref} testID="social-nertworks-input-question-item">
      <InputField
        error={validationError}
        hint={hint}
        icon={icon}
        label={label}
        value={selectedAnswer}
        input={({ onBlur, onFocus, style }) => (
          <TextInput
            autoCapitalize="none"
            style={style}
            testID="social-input"
            value={selectedAnswer}
            onBlur={onBlur}
            onChange={handleOnChange}
            onFocus={onFocus}
          />
        )}
      />
    </View>
  );
};

export { SocialNetworksInputQuestionItem };
