import { useCallback } from "react";
import { Country } from "@lookiero/sty-psp-locale";
import { Segment } from "@lookiero/sty-psp-segment";
import { TrackingEventCategory, useEmitUserEvent } from "@lookiero/sty-psp-tracking";
import { PROJECT, TrackingEventName, UploadPhotoOptionsModalViewTrackingEvent } from "./tracking";

interface TrackUploadPhotoOptionsModalViewFunction {
  (): void;
}

interface UseTrackUploadPhotoOptionsModalViewFunctionArgs {
  readonly country: Country;
  readonly segment: Segment;
  readonly customerId: string;
}

interface UseTrackUploadPhotoOptionsModalViewFunction {
  (agrs: UseTrackUploadPhotoOptionsModalViewFunctionArgs): TrackUploadPhotoOptionsModalViewFunction;
}

const useTrackUploadPhotoOptionsModalView: UseTrackUploadPhotoOptionsModalViewFunction = ({
  country,
  segment,
  customerId,
}) => {
  const emitUserEvent = useEmitUserEvent<UploadPhotoOptionsModalViewTrackingEvent>();

  const trackUploadPhotoOptionsModalView: TrackUploadPhotoOptionsModalViewFunction = useCallback(() => {
    emitUserEvent({
      event: TrackingEventName.UPLOAD_PHOTO_OPTIONS_MODAL_VIEW,
      eventCategory: TrackingEventCategory.NAVIGATION,
      section: `${PROJECT}_photos`,
      store: country,
      segment,
      userId: customerId,
    });
  }, [country, emitUserEvent, customerId, segment]);

  return trackUploadPhotoOptionsModalView;
};

export { useTrackUploadPhotoOptionsModalView };
