import { useQuery, UseQueryFunctionResult } from "@lookiero/messaging-react";
import { ScreenProjection } from "@lookiero/sty-psp-quiz-style-profile-common-ui";
import { viewScreenBySlugAndCustomerId } from "../../../../projection/screen/viewScreenBySlugAndCustomerId";
import { MESSAGING_CONTEXT_ID } from "../../../delivery/baseBootstrap";

interface UseViewScreenBySlugAndCustomerIdFunctionArgs {
  readonly slug: string | undefined;
  readonly customerId: string;
  readonly experiment: string | undefined;
  readonly enabled: boolean;
}

interface UseViewScreenBySlugAndCustomerIdFunction {
  (args: UseViewScreenBySlugAndCustomerIdFunctionArgs): UseQueryFunctionResult<ScreenProjection>;
}

const useViewScreenBySlugAndCustomerId: UseViewScreenBySlugAndCustomerIdFunction = ({
  slug,
  customerId,
  experiment,
  enabled,
}) =>
  useQuery({
    query: viewScreenBySlugAndCustomerId({ slug: slug as string, customerId, experiment }),
    contextId: MESSAGING_CONTEXT_ID,
    options: {
      staleTime: Infinity,
      retry: false,
      refetchOnWindowFocus: false,
      enabled: Boolean(slug) && enabled,
    },
  });

export { useViewScreenBySlugAndCustomerId };
