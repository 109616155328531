import { domainEvent, DomainEvent, MessageName } from "@lookiero/messaging";

const IMAGE_UPLOADED = "image_uploaded";

interface ImageUploadedPayload {
  readonly aggregateId: string;
}

interface ImageUploaded extends DomainEvent<typeof IMAGE_UPLOADED>, ImageUploadedPayload {}

interface ImageUploadedFunction {
  (payload: ImageUploadedPayload): ImageUploaded;
}

const imageUploaded: ImageUploadedFunction = ({ aggregateId }) => domainEvent({ aggregateId, name: IMAGE_UPLOADED });

const isImageUploaded = (event: DomainEvent<MessageName>): event is ImageUploaded => event.name === IMAGE_UPLOADED;

export type { ImageUploaded };
export { IMAGE_UPLOADED, isImageUploaded, imageUploaded };
