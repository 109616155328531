import {
  AggregateRoot,
  RepositoryGetFunction,
  RepositoryGetFunctionArgs,
  RepositorySaveFunction,
  RepositorySaveFunctionArgs,
} from "@lookiero/messaging";
import { BuildBootstrapFunctionReturn, bootstrap as messagingBootstrap } from "@lookiero/messaging-react";
import {
  bootstrapWithBuilder as bootstrapNotifications,
  inMemoryStorageNotifications,
} from "@lookiero/sty-psp-notifications";
import { bootstrapWithBuilder as bootstrapQuizStyleProfileCommonUi } from "@lookiero/sty-psp-quiz-style-profile-common-ui";
import { UPLOAD_IMAGE } from "../../domain/image/command/uploadImage";
import { Image, uploadImageHandler } from "../../domain/image/model/image";
import { ImagesGetFunction, ImagesSaveFunction } from "../../domain/image/model/images";
import { FILL_SCREEN } from "../../domain/screen/command/fillScreen";
import { Screen, fillScreenHandler } from "../../domain/screen/model/screen";
import { ScreensGetFunction, ScreensSaveFunction } from "../../domain/screen/model/screens";
import { ImageByIdView, VIEW_IMAGE_BY_ID, viewImageByIdHandler } from "../../projection/image/viewImageById";
import {
  ScreenBySlugAndCustomerIdView,
  VIEW_SCREEN_BY_SLUG_AND_CUSTOMER_ID,
  viewScreenBySlugAndCustomerIdHandler,
} from "../../projection/screen/viewScreenBySlugAndCustomerId";
import {
  StyleProfileAnswersByCustomerIdView,
  VIEW_STYLE_PROFILE_ANSWERS_BY_CUSTOMER_ID,
  viewStyleProfileAnswersByCustomerIdHandler,
} from "../../projection/styleProfileAnswers/viewStyleProfileAnswersByCustomerId";
import {
  StyleProfileMenuByCustomerIdView,
  VIEW_STYLE_PROFILE_MENU_BY_CUSTOMER_ID,
  viewStyleProfileMenuByCustomerIdHandler,
} from "../../projection/styleProfileMenu/viewStyleProfileMenuByCustomerId";

const MESSAGING_CONTEXT_ID = "StyleProfile";

type NeverWhenEmptyRecord<K extends [Record<string, unknown>]> = K extends [Record<string, never>] ? [never?] : K;
type RepositoryDependencies<
  A extends AggregateRoot,
  GetFunctionArgs extends RepositoryGetFunctionArgs,
  SaveFunctionArgs extends RepositorySaveFunctionArgs,
> = Omit<
  Parameters<RepositoryGetFunction<A, GetFunctionArgs>>[0] & Parameters<RepositorySaveFunction<A, SaveFunctionArgs>>[0],
  keyof RepositoryGetFunctionArgs
>;
// type ProcessManagerDependencies<Args extends ProcessManagerFunctionArgs> = Omit<
//   Parameters<ProcessManagerFunction<DomainEvent<MessageName>, Args>>[0],
//   keyof ProcessManagerFunctionArgs
// >;

interface BaseBootstrapFunctionArgs<
  ScreenGetFunctionArgs extends RepositoryGetFunctionArgs,
  ScreenSaveFunctionArgs extends RepositorySaveFunctionArgs,
  ImageGetFunctionArgs extends RepositoryGetFunctionArgs,
  ImageSaveFunctionArgs extends RepositorySaveFunctionArgs,
> {
  readonly styleProfileMenuByCustomerIdView: StyleProfileMenuByCustomerIdView;
  readonly screenBySlugAndCustomerIdView: ScreenBySlugAndCustomerIdView;
  readonly styleProfileAnswersByCustomerIdView: StyleProfileAnswersByCustomerIdView;
  readonly getScreen: ScreensGetFunction<ScreenGetFunctionArgs>;
  readonly saveScreen: ScreensSaveFunction<ScreenSaveFunctionArgs>;
  readonly screensDependencies: NeverWhenEmptyRecord<
    [RepositoryDependencies<Screen, ScreenGetFunctionArgs, ScreenSaveFunctionArgs>]
  >;
  readonly imageByIdView: ImageByIdView;
  readonly getImage: ImagesGetFunction<ImageGetFunctionArgs>;
  readonly saveImage: ImagesSaveFunction<ImageSaveFunctionArgs>;
  readonly imagesDependencies: NeverWhenEmptyRecord<
    [RepositoryDependencies<Image, ImageGetFunctionArgs, ImageSaveFunctionArgs>]
  >;
}

interface BaseBootstrapFunction {
  <
    ScreenGetFunctionArgs extends RepositoryGetFunctionArgs,
    ScreenSaveFunctionArgs extends RepositorySaveFunctionArgs,
    ImageGetFunctionArgs extends RepositoryGetFunctionArgs,
    ImageSaveFunctionArgs extends RepositorySaveFunctionArgs,
  >(
    args: BaseBootstrapFunctionArgs<
      ScreenGetFunctionArgs,
      ScreenSaveFunctionArgs,
      ImageGetFunctionArgs,
      ImageSaveFunctionArgs
    >,
  ): BuildBootstrapFunctionReturn;
}

const baseBootstrap: BaseBootstrapFunction = ({
  styleProfileMenuByCustomerIdView,
  screenBySlugAndCustomerIdView,
  styleProfileAnswersByCustomerIdView,
  screensDependencies,
  getScreen,
  saveScreen,
  imageByIdView,
  imagesDependencies,
  getImage,
  saveImage,
}) => {
  let messaging = messagingBootstrap({ id: MESSAGING_CONTEXT_ID })
    .query(VIEW_STYLE_PROFILE_MENU_BY_CUSTOMER_ID, viewStyleProfileMenuByCustomerIdHandler, {
      view: styleProfileMenuByCustomerIdView,
    })
    .query(VIEW_SCREEN_BY_SLUG_AND_CUSTOMER_ID, viewScreenBySlugAndCustomerIdHandler, {
      view: screenBySlugAndCustomerIdView,
    })
    .query(VIEW_STYLE_PROFILE_ANSWERS_BY_CUSTOMER_ID, viewStyleProfileAnswersByCustomerIdHandler, {
      view: styleProfileAnswersByCustomerIdView,
    })
    .command(FILL_SCREEN, fillScreenHandler)(getScreen, saveScreen, ...screensDependencies)
    .query(VIEW_IMAGE_BY_ID, viewImageByIdHandler, {
      view: imageByIdView,
    })
    .command(UPLOAD_IMAGE, uploadImageHandler)(getImage, saveImage, ...imagesDependencies);

  messaging = bootstrapNotifications({ messaging, storage: inMemoryStorageNotifications() });

  messaging = bootstrapQuizStyleProfileCommonUi({ messaging });

  return messaging.build();
};

export { MESSAGING_CONTEXT_ID, baseBootstrap };
