import React, { FC, RefObject, useCallback, useRef, useState } from "react";
import { Platform, ScrollView, View } from "react-native";
import { useLocation } from "react-router-native";
import { Text, Spinner } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import { LookAndLike } from "@lookiero/look-and-like";
import { QueryStatus } from "@lookiero/messaging-react";
import { Body } from "@lookiero/sty-psp-quiz-style-profile-common-ui";
import { Layout as UiLayout, useScreenSize, useScrollTo } from "@lookiero/sty-psp-ui";
import { useViewStyleProfileAnswersByCustomerId } from "../../../projection/styleProfileAnswers/react/useViewStyleProfileAnswersByCustomerId";
import { useViewStyleProfileMenuByCustomerId } from "../../../projection/styleProfileMenu/react/useViewStyleProfileMenuByCustomerId";
import { TrackingPage } from "../../../tracking/tracking";
import { useTrackPageView } from "../../../tracking/useTrackPageView";
import { HEADER_HEIGHT } from "../../components/templates/header/Header.style";
import { HomeHeader } from "../../components/templates/header/homeHeader/HomeHeader";
import { useStaticInfo } from "../../hooks/useStaticInfo";
import { I18nMessages, PREFIX } from "../../i18n/i18n";
import { style } from "./Home.style";
import { Menu } from "./components/menu/Menu";

interface HomeProps {
  readonly layout: UiLayout;
  readonly lookAndLike: LookAndLike;
  readonly onBack: () => void;
}

const Home: FC<HomeProps> = ({ layout: Layout, lookAndLike: LookAndLike, onBack }) => {
  const { state } = useLocation();
  const screenSize = useScreenSize();

  const titleText = useI18nMessage({ id: I18nMessages.HOME_TITLE, prefix: PREFIX });
  const descriptionText = useI18nMessage({ id: I18nMessages.HOME_DESCRIPTION, prefix: PREFIX });

  const {
    customer: { customerId, country, segment },
  } = useStaticInfo();
  const [menu] = useViewStyleProfileMenuByCustomerId({ customerId });
  const [answers, answersStatus] = useViewStyleProfileAnswersByCustomerId({ customerId });

  const scrollRef = useRef<ScrollView>(null);
  const scrollTo = useScrollTo({ scrollRef });
  const handleOnMenuItemSelected = useCallback(
    ({ ref }: { ref: RefObject<View> }) => setTimeout(() => scrollTo({ ref, y: -HEADER_HEIGHT }), 100),
    [scrollTo],
  );
  const [scrollEnabled, setScrollEnabled] = useState(true);
  const disableScroll = useCallback(() => setScrollEnabled(false), []);
  const enableScroll = useCallback(() => setScrollEnabled(true), []);

  const answersLoaded = answersStatus === QueryStatus.SUCCESS;
  const displayLookAndLike = Platform.OS === "web" && Boolean(answers);

  const [lookAndLikeRenderedError, setLookAndLikeRenderedError] = useState(false);
  const [lookAndLikeRendered, setLookAndLikeRendered] = useState(false);
  const handleOnLookAndLikeReady = useCallback(() => setLookAndLikeRendered(true), []);
  const handleOnLookAndLikeError = useCallback(() => {
    setLookAndLikeRendered(true);
    setLookAndLikeRenderedError(true);
  }, []);

  useTrackPageView({
    country,
    customerId,
    page: TrackingPage.STYLE,
    segment,
  });

  const selectedMenuItemIndex = state?.slug
    ? menu?.findIndex((menuItem) => menuItem.screens.find((screen) => screen.slug === state.slug))
    : undefined;

  if (!answersLoaded) {
    return <Spinner testID="spinner" />;
  }

  return (
    <Layout
      header={Platform.OS === "web" ? <HomeHeader onBack={onBack} /> : undefined}
      scrollEnabled={scrollEnabled}
      scrollRef={scrollRef}
    >
      <Body style={{ row: style.bodyRow }}>
        {displayLookAndLike && (
          <LookAndLike
            style={style.lookAndLike}
            view="style-profile"
            onBlur={enableScroll}
            onError={handleOnLookAndLikeError}
            onFocus={disableScroll}
            onReady={handleOnLookAndLikeReady}
          />
        )}

        {(!displayLookAndLike || lookAndLikeRendered) && (
          <>
            {displayLookAndLike && !lookAndLikeRenderedError && screenSize === "S" && <View style={style.divider} />}

            <View style={style.content}>
              <Text level={3} style={style.title} heading>
                {titleText}
              </Text>
              <Text level={3} style={style.description}>
                {descriptionText}
              </Text>
            </View>

            <View style={style.bodyGap}>
              {menu ? (
                <Menu activeIndex={selectedMenuItemIndex} menu={menu} onItemSelected={handleOnMenuItemSelected} />
              ) : (
                <Spinner testID="spinner" />
              )}
            </View>
          </>
        )}
      </Body>
    </Layout>
  );
};

export { Home };
