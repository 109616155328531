import { HttpPostFunction } from "@lookiero/sty-psp-http";
import { ImageProjection } from "../../../projection/image/image";
import { ImageByIdView, ViewImageByIdResult } from "../../../projection/image/viewImageById";

interface HttpImageByIdView extends ImageByIdView {}

interface HttpImageByIdViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpImageByIdViewFunction {
  (args: HttpImageByIdViewFunctionArgs): HttpImageByIdView;
}

interface ViewImageByIdResponse {
  readonly result: ImageProjection;
}

const httpImageByIdView: HttpImageByIdViewFunction =
  ({ httpPost }) =>
  async ({ id, signal }) =>
    await httpPost<ViewImageByIdResponse, ViewImageByIdResult>({
      endpoint: "/view-style-profile-image-by-id",
      body: {
        id,
      },
      signal,
      result: {
        error: null,
        success: (response) => response.result,
      },
    });

export { httpImageByIdView };
