import { PortalProvider } from "@gorhom/portal";
import React, { FC } from "react";
import { StatusBar } from "react-native";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { Notifications } from "@lookiero/sty-psp-notifications";
import { theme } from "@lookiero/sty-psp-ui";
import { MESSAGING_CONTEXT_ID } from "../../delivery/baseBootstrap";

const { colorBgBase } = theme();

interface AppProps {
  readonly children: JSX.Element;
  readonly iOSInAppBrowserVideoAd: boolean;
  readonly iOSInAppBrowserVideoAdBannerHeight: number;
}

const App: FC<AppProps> = ({ children, iOSInAppBrowserVideoAd, iOSInAppBrowserVideoAdBannerHeight }) => (
  <SafeAreaProvider>
    <PortalProvider>
      <StatusBar backgroundColor={colorBgBase} barStyle="dark-content" translucent />
      <Notifications
        contextId={MESSAGING_CONTEXT_ID}
        style={{ toast: iOSInAppBrowserVideoAd && { top: iOSInAppBrowserVideoAdBannerHeight } }}
      />
      {children}
    </PortalProvider>
  </SafeAreaProvider>
);

export { App };
