import { DomainEvent, MessageName } from "@lookiero/messaging";
import { useQuery, UseQueryFunctionResult } from "@lookiero/messaging-react";
import { isScreenFilled } from "../../../../domain/screen/model/screenFilled";
import { StyleProfileMenu } from "../../../../projection/styleProfileMenu/styleProfileMenu";
import { viewStyleProfileMenuByCustomerId } from "../../../../projection/styleProfileMenu/viewStyleProfileMenuByCustomerId";
import { MESSAGING_CONTEXT_ID } from "../../../delivery/baseBootstrap";

interface UseViewStyleProfileMenuByCustomerIdFunctionArgs {
  readonly customerId: string;
}

interface UseViewStyleProfileMenuByCustomerIdFunction {
  (args: UseViewStyleProfileMenuByCustomerIdFunctionArgs): UseQueryFunctionResult<StyleProfileMenu>;
}

const shouldInvalidate = (event: DomainEvent<MessageName>) => isScreenFilled(event);

const useViewStyleProfileMenuByCustomerId: UseViewStyleProfileMenuByCustomerIdFunction = ({ customerId }) =>
  useQuery({
    query: viewStyleProfileMenuByCustomerId({ customerId }),
    contextId: MESSAGING_CONTEXT_ID,
    invalidation: shouldInvalidate,
    options: {
      refetchOnMount: "always",
      staleTime: Infinity,
      retry: false,
      refetchOnWindowFocus: false,
    },
  });

export { useViewStyleProfileMenuByCustomerId };
