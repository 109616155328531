import { useCallback } from "react";
import { Country } from "@lookiero/sty-psp-locale";
import { Segment } from "@lookiero/sty-psp-segment";
import { TrackingEventCategory, useEmitUserEvent } from "@lookiero/sty-psp-tracking";
import { PROJECT, TrackingEventName, ImageUploadedTrackingEvent } from "./tracking";

interface TrackImageUploadedFunction {
  (): void;
}

interface UseTrackImageUploadedFunctionArgs {
  readonly country: Country;
  readonly segment: Segment;
  readonly customerId: string;
}

interface UseTrackImageUploadedFunction {
  (agrs: UseTrackImageUploadedFunctionArgs): TrackImageUploadedFunction;
}

const useTrackImageUploaded: UseTrackImageUploadedFunction = ({ country, segment, customerId }) => {
  const emitUserEvent = useEmitUserEvent<ImageUploadedTrackingEvent>();

  const trackImageUploaded: TrackImageUploadedFunction = useCallback(() => {
    emitUserEvent({
      event: TrackingEventName.IMAGE_UPLOADED,
      eventCategory: TrackingEventCategory.NAVIGATION,
      section: `${PROJECT}_photos`,
      store: country,
      segment,
      userId: customerId,
    });
  }, [country, customerId, emitUserEvent, segment]);

  return trackImageUploaded;
};

export { useTrackImageUploaded };
