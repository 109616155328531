/* eslint-disable react/prop-types */
import React from "react";
import {
  HostScreenQuestionItem as HostScreenQuestionItemCommonUi,
  QuestionItem,
  QuestionType,
} from "@lookiero/sty-psp-quiz-style-profile-common-ui";
import { HostScreenCta } from "../hostScreenCta/HostScreenCta";

const HostScreenQuestionItem: QuestionItem<QuestionType.HOST_SCREEN> = ({ question, level, children }) => (
  <HostScreenQuestionItemCommonUi
    cta={<HostScreenCta question={question} />}
    level={level}
    question={question}
    questionParentId=""
  >
    {children}
  </HostScreenQuestionItemCommonUi>
);

export { HostScreenQuestionItem };
