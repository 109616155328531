import { HttpPostFunction } from "@lookiero/sty-psp-http";
import { StyleProfileMenu } from "../../../projection/styleProfileMenu/styleProfileMenu";
import {
  StyleProfileMenuByCustomerIdView,
  ViewStyleProfileMenuByCustomerIdResult,
} from "../../../projection/styleProfileMenu/viewStyleProfileMenuByCustomerId";

interface HttpStyleProfileMenuByCustomerIdView extends StyleProfileMenuByCustomerIdView {}

interface HttpStyleProfileMenuByCustomerIdViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpStyleProfileMenuByCustomerIdViewFunction {
  (args: HttpStyleProfileMenuByCustomerIdViewFunctionArgs): HttpStyleProfileMenuByCustomerIdView;
}

const httpStyleProfileMenuByCustomerIdView: HttpStyleProfileMenuByCustomerIdViewFunction =
  ({ httpPost }) =>
  async ({ customerId, signal }) =>
    await httpPost<StyleProfileMenu, ViewStyleProfileMenuByCustomerIdResult>({
      endpoint: "/view-style-profile-menu-by-customer-id",
      body: { customerId },
      signal,
      result: {
        error: null,
        success: (response) => response,
      },
    });

export { httpStyleProfileMenuByCustomerIdView };
