import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { ImageProjection } from "./image";

const VIEW_IMAGE_BY_ID = "view_image_by_id";

interface ViewImageByIdPayload {
  readonly id: string;
}

interface ViewImageById extends Query<typeof VIEW_IMAGE_BY_ID>, ViewImageByIdPayload {}

interface ViewImageByIdFunction {
  (payload: ViewImageByIdPayload): ViewImageById;
}

const viewImageById: ViewImageByIdFunction = (payload) => ({
  ...query({ name: VIEW_IMAGE_BY_ID }),
  ...payload,
});

type ViewImageByIdResult = ImageProjection | null;

interface ImageByIdViewArgs extends CancelableQueryViewArgs {
  readonly id: string;
}

interface ImageByIdView {
  (args: ImageByIdViewArgs): Promise<ViewImageByIdResult>;
}

interface ViewImageByIdHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: ImageByIdView;
}

const viewImageByIdHandler: QueryHandlerFunction<
  ViewImageById,
  ViewImageByIdResult,
  ViewImageByIdHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ id }) =>
    view({ id, signal });

export type { ViewImageById, ImageByIdView, ViewImageByIdResult };
export { VIEW_IMAGE_BY_ID, viewImageById, viewImageByIdHandler };
