import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const { colorTextMedium } = theme();

const style = StyleSheet.create({
  description: {
    color: colorTextMedium,
  },
});

export { style };
