import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const { space8, space10 } = theme();

const style = StyleSheet.create({
  bodyColumn: {
    paddingHorizontal: space8,
  },
  container: {
    flex: 1,
    paddingBottom: space10,
  },
  description: {
    marginBottom: space8,
    textAlign: "center",
  },
  image: {
    flex: 1,
  },
});

export { style };
