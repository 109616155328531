import { useCallback } from "react";
import { Country } from "@lookiero/sty-psp-locale";
import { Segment } from "@lookiero/sty-psp-segment";
import { TrackingEventCategory, useEmitUserEvent } from "@lookiero/sty-psp-tracking";
import { PROJECT, TrackingEventName, UploadPhotoChooseLibraryOptionSelectedTrackingEvent } from "./tracking";

interface TrackUploadPhotoChooseLibraryOptionSelectedFunction {
  (): void;
}

interface UseTrackUploadPhotoChooseLibraryOptionSelectedFunctionArgs {
  readonly country: Country;
  readonly segment: Segment;
  readonly customerId: string;
}

interface UseTrackUploadPhotoChooseLibraryOptionSelectedFunction {
  (
    agrs: UseTrackUploadPhotoChooseLibraryOptionSelectedFunctionArgs,
  ): TrackUploadPhotoChooseLibraryOptionSelectedFunction;
}

const useTrackUploadPhotoChooseLibraryOptionSelected: UseTrackUploadPhotoChooseLibraryOptionSelectedFunction = ({
  country,
  segment,
  customerId,
}) => {
  const emitUserEvent = useEmitUserEvent<UploadPhotoChooseLibraryOptionSelectedTrackingEvent>();

  const trackUploadPhotoChooseLibraryOptionSelected: TrackUploadPhotoChooseLibraryOptionSelectedFunction =
    useCallback(() => {
      emitUserEvent({
        event: TrackingEventName.UPLOAD_PHOTO_CHOOSE_LIBRARY_OPTION_SELECTED,
        eventCategory: TrackingEventCategory.NAVIGATION,
        section: `${PROJECT}_photos`,
        store: country,
        segment,
        userId: customerId,
      });
    }, [country, emitUserEvent, customerId, segment]);

  return trackUploadPhotoChooseLibraryOptionSelected;
};

export { useTrackUploadPhotoChooseLibraryOptionSelected };
