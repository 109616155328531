import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const { space8 } = theme();

const style = StyleSheet.create({
  bodyColumn: {
    paddingVertical: space8,
  },
  scrollView: {
    flexGrow: 0,
  },
});

export { style };
