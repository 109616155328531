import { useEffect, useState } from "react";
import { readFileAsDataURL } from "../../file/readFileAsDataURL";
import { useStaticInfo } from "./useStaticInfo";

type Image = string | undefined;
type Status = "idle" | "loading" | "success" | "error";

type UseFecthImageFunctionResult = [image: Image, status: Status];

interface UseFecthImageFunctionArgs {
  readonly endpoint: string;
  readonly enabled?: boolean;
}
interface UseFecthImageFunction {
  (args: UseFecthImageFunctionArgs): UseFecthImageFunctionResult;
}
const useFecthImage: UseFecthImageFunction = ({ endpoint, enabled = true }) => {
  const { apiUrl, getAuthToken } = useStaticInfo();
  const [image, setImage] = useState<string>();
  const [status, setStatus] = useState<Status>("idle");

  useEffect(() => {
    const fetchImage = async () => {
      if (!enabled) {
        return;
      }

      setStatus("loading");

      try {
        const response = await fetch(`${apiUrl()}${endpoint}`, {
          method: "GET",
          headers: {
            Authorization: getAuthToken ? `Bearer ${await getAuthToken()}` : "",
          },
        });

        const data = await response.blob();
        const image = await readFileAsDataURL({ file: data });

        setImage(image);
        setStatus("success");
      } catch (error) {
        setStatus("error");
      }
    };

    fetchImage();
  }, [apiUrl, enabled, endpoint, getAuthToken]);

  return [image, status];
};

export { useFecthImage };
