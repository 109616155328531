import { domainEvent, DomainEvent, MessageName } from "@lookiero/messaging";

const SCREEN_FILLED = "screen_filled";

interface ScreenFilledPayload {
  readonly aggregateId: string;
}

interface ScreenFilled extends DomainEvent<typeof SCREEN_FILLED> {}

interface ScreenFilledFunction {
  (payload: ScreenFilledPayload): ScreenFilled;
}

const screenFilled: ScreenFilledFunction = ({ aggregateId }) => domainEvent({ aggregateId, name: SCREEN_FILLED });

const isScreenFilled = (event: DomainEvent<MessageName>): event is ScreenFilled => event.name === SCREEN_FILLED;

export type { ScreenFilled };
export { SCREEN_FILLED, isScreenFilled, screenFilled };
