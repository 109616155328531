/* eslint-disable react/prop-types */
import React from "react";
import {
  HostScreenWithSummaryQuestionItem as HostScreenWithSummaryQuestionItemCommonUi,
  QuestionItem,
  QuestionType,
} from "@lookiero/sty-psp-quiz-style-profile-common-ui";
import { HostScreenCta } from "../hostScreenCta/HostScreenCta";

const HostScreenWithSummaryQuestionItem: QuestionItem<QuestionType.HOST_SCREEN_WITH_SUMMARY> = ({
  question,
  level,
  children,
  questionParentId,
}) => (
  <HostScreenWithSummaryQuestionItemCommonUi
    cta={<HostScreenCta question={question} />}
    level={level}
    question={question}
    questionParentId={questionParentId}
  >
    {children}
  </HostScreenWithSummaryQuestionItemCommonUi>
);

export { HostScreenWithSummaryQuestionItem };
