import React, { FC, ReactNode, createContext, useContext } from "react";
import { useParams } from "react-router-native";
import invariant from "tiny-invariant";
import { UseQueryFunctionResult } from "@lookiero/messaging-react";
import { ScreenProjection } from "@lookiero/sty-psp-quiz-style-profile-common-ui";
import { useViewScreenBySlugAndCustomerId } from "../../projection/screen/react/useViewScreenBySlugAndCustomerId";
import { useStaticInfo } from "./useStaticInfo";

type ScreenContextApi = UseQueryFunctionResult<ScreenProjection>;

const ScreenContext = createContext<ScreenContextApi>(null as unknown as ScreenContextApi);

interface ScreenProviderProps {
  readonly children: ReactNode;
}

const ScreenProvider: FC<ScreenProviderProps> = ({ children }) => {
  const { slug } = useParams();
  const {
    customer: { customerId },
  } = useStaticInfo();
  const useViewScreenBySlugAndCustomerIdResult = useViewScreenBySlugAndCustomerId({
    slug,
    customerId,
    experiment: undefined,
    enabled: true,
  });

  return <ScreenContext.Provider value={useViewScreenBySlugAndCustomerIdResult}>{children}</ScreenContext.Provider>;
};

interface UseScreenFunction {
  (): ScreenContextApi;
}

const useScreen: UseScreenFunction = () => {
  const screen = useContext(ScreenContext);

  invariant(screen, "Your are trying to use the useScreen hook without wrapping your app with the <ScreenProvider>.");

  return screen;
};

export { ScreenProvider, useScreen };
