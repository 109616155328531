import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { StyleProfileMenu } from "./styleProfileMenu";

const VIEW_STYLE_PROFILE_MENU_BY_CUSTOMER_ID = "view_style_profile_menu_by_customer_id";

interface ViewStyleProfileMenuByCustomerIdPayload {
  readonly customerId: string;
}

interface ViewStyleProfileMenuByCustomerId
  extends Query<typeof VIEW_STYLE_PROFILE_MENU_BY_CUSTOMER_ID>,
    ViewStyleProfileMenuByCustomerIdPayload {}

interface ViewStyleProfileMenuByCustomerIdFunction {
  (payload: ViewStyleProfileMenuByCustomerIdPayload): ViewStyleProfileMenuByCustomerId;
}

const viewStyleProfileMenuByCustomerId: ViewStyleProfileMenuByCustomerIdFunction = (payload) => ({
  ...query({ name: VIEW_STYLE_PROFILE_MENU_BY_CUSTOMER_ID }),
  ...payload,
});

type ViewStyleProfileMenuByCustomerIdResult = StyleProfileMenu | null;

interface StyleProfileMenuByCustomerIdViewArgs extends CancelableQueryViewArgs {
  readonly customerId: string;
}

interface StyleProfileMenuByCustomerIdView {
  (args: StyleProfileMenuByCustomerIdViewArgs): Promise<ViewStyleProfileMenuByCustomerIdResult>;
}

interface ViewStyleProfileMenuByCustomerIdHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: StyleProfileMenuByCustomerIdView;
}

const viewStyleProfileMenuByCustomerIdHandler: QueryHandlerFunction<
  ViewStyleProfileMenuByCustomerId,
  ViewStyleProfileMenuByCustomerIdResult,
  ViewStyleProfileMenuByCustomerIdHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ customerId }) =>
    view({ customerId, signal });

export type { StyleProfileMenuByCustomerIdView, ViewStyleProfileMenuByCustomerIdResult };
export {
  VIEW_STYLE_PROFILE_MENU_BY_CUSTOMER_ID,
  viewStyleProfileMenuByCustomerId,
  viewStyleProfileMenuByCustomerIdHandler,
};
