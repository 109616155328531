import { HttpPostFunction } from "@lookiero/sty-psp-http";
import { AnswersProjection } from "@lookiero/sty-psp-quiz-style-profile-common-ui";
import {
  StyleProfileAnswersByCustomerIdView,
  ViewStyleProfileAnswersByCustomerIdResult,
} from "../../../projection/styleProfileAnswers/viewStyleProfileAnswersByCustomerId";

interface HttpStyleProfileAnswersByCustomerIdView extends StyleProfileAnswersByCustomerIdView {}

interface HttpStyleProfileAnswersByCustomerIdViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpStyleProfileAnswersByCustomerIdViewFunction {
  (args: HttpStyleProfileAnswersByCustomerIdViewFunctionArgs): HttpStyleProfileAnswersByCustomerIdView;
}

const httpStyleProfileAnswersByCustomerIdView: HttpStyleProfileAnswersByCustomerIdViewFunction =
  ({ httpPost }) =>
  async ({ customerId, signal }) =>
    await httpPost<AnswersProjection, ViewStyleProfileAnswersByCustomerIdResult>({
      endpoint: "/view-style-profile-answers-by-customer-id",
      body: { customerId },
      signal,
      result: {
        error: {},
        success: (response) => response,
      },
    });

export { httpStyleProfileAnswersByCustomerIdView };
