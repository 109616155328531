import React, { FC } from "react";
import { View, ViewProps } from "react-native";
import { style } from "./Header.style";

type HeaderProps = ViewProps;

const Header: FC<HeaderProps> = ({ children, style: customStyle, ...props }) => (
  <View {...props} style={[style.header, customStyle]}>
    {children}
  </View>
);

export { Header };
