import { useCallback } from "react";
import { Country } from "@lookiero/sty-psp-locale";
import { Segment } from "@lookiero/sty-psp-segment";
import { TrackingEventCategory, useEmitUserEvent } from "@lookiero/sty-psp-tracking";
import { NavigationBackTrackingEvent, PROJECT, TrackingEventName } from "./tracking";

interface TrackScreenNavigationBackFunctionArgs {
  readonly slug: string | undefined;
  readonly group: string | undefined;
}
interface TrackScreenNavigationBackFunction {
  (args: TrackScreenNavigationBackFunctionArgs): void;
}

interface UseTrackScreenNavigationBackFunctionArgs {
  readonly country: Country;
  readonly segment: Segment;
  readonly customerId: string;
}

interface UseTrackScreenNavigationBackFunction {
  (agrs: UseTrackScreenNavigationBackFunctionArgs): TrackScreenNavigationBackFunction;
}

const useTrackScreenNavigationBack: UseTrackScreenNavigationBackFunction = ({ country, segment, customerId }) => {
  const emitUserEvent = useEmitUserEvent<NavigationBackTrackingEvent>();

  const trackScreenNavigationBack: TrackScreenNavigationBackFunction = useCallback(
    ({ slug, group }) => {
      if (!(slug && group)) {
        return;
      }

      emitUserEvent({
        event: TrackingEventName.NAVIGATION_BACK,
        eventCategory: TrackingEventCategory.NAVIGATION,
        section: `${PROJECT}_${slug}`,
        store: country,
        segment,
        userId: customerId,
        group,
      });
    },
    [country, customerId, emitUserEvent, segment],
  );

  return trackScreenNavigationBack;
};

export { useTrackScreenNavigationBack };
