import { Platform } from "react-native";
import { BuildBootstrapFunctionReturn } from "@lookiero/messaging-react";
import { fetchHttpPost, multipartFetchHttpPost } from "@lookiero/sty-psp-http";
import { VERSION } from "../../version";
import { getImage, saveImage } from "../domain/image/model/httpImages";
import { getScreen, saveScreen } from "../domain/screen/model/httpScreens";
import { httpImageByIdView } from "../projection/image/httpImageByIdView";
import { httpScreenBySlugAndCustomerIdView } from "../projection/screen/httpScreenBySlugAndCustomerIdView";
import { httpStyleProfileAnswersByCustomerIdView } from "../projection/styleProfileAnswers/httpStyleProfileAnswersByCustomerIdView";
import { httpStyleProfileMenuByCustomerIdView } from "../projection/styleProfileMenu/httpStyleProfileMenuByCustomerIdView";
import { baseBootstrap } from "./baseBootstrap";

type OS = typeof Platform.OS;
type Device = Exclude<OS, "macos" | "windows">;
const device = Platform.OS as Device;

interface BootstrapFunctionArgs {
  readonly apiUrl: () => string;
  readonly getAuthToken: () => Promise<string>;
}

interface BootstrapFunction {
  (args: BootstrapFunctionArgs): BuildBootstrapFunctionReturn;
}

const bootstrap: BootstrapFunction = ({ apiUrl, getAuthToken }) => {
  const httpPost = fetchHttpPost({ apiUrl, getAuthToken, device, version: VERSION });
  const multipartHttpPost = multipartFetchHttpPost({ apiUrl, getAuthToken, device, version: VERSION });

  return baseBootstrap({
    styleProfileMenuByCustomerIdView: httpStyleProfileMenuByCustomerIdView({ httpPost }),
    screenBySlugAndCustomerIdView: httpScreenBySlugAndCustomerIdView({ httpPost }),
    styleProfileAnswersByCustomerIdView: httpStyleProfileAnswersByCustomerIdView({ httpPost }),
    getScreen,
    saveScreen,
    screensDependencies: [{ httpPost }],
    imageByIdView: httpImageByIdView({ httpPost }),
    getImage,
    saveImage,
    imagesDependencies: [{ multipartHttpPost }],
  });
};

export { bootstrap };
