import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { ScreenProjection } from "@lookiero/sty-psp-quiz-style-profile-common-ui";

const VIEW_SCREEN_BY_SLUG_AND_CUSTOMER_ID = "view_screen_by_slug_and_customer_id";

interface ViewScreenBySlugAndCustomerIdPayload {
  readonly slug: string;
  readonly customerId: string;
  readonly experiment?: string;
}

interface ViewScreenBySlugAndCustomerId
  extends Query<typeof VIEW_SCREEN_BY_SLUG_AND_CUSTOMER_ID>,
    ViewScreenBySlugAndCustomerIdPayload {}

interface ViewScreenBySlugAndCustomerIdFunction {
  (payload: ViewScreenBySlugAndCustomerIdPayload): ViewScreenBySlugAndCustomerId;
}

const viewScreenBySlugAndCustomerId: ViewScreenBySlugAndCustomerIdFunction = (payload) => ({
  ...query({ name: VIEW_SCREEN_BY_SLUG_AND_CUSTOMER_ID }),
  ...payload,
});

type ViewScreenBySlugAndCustomerIdResult = ScreenProjection | null;

interface ScreenBySlugAndCustomerIdViewArgs extends CancelableQueryViewArgs {
  readonly slug: string;
  readonly customerId: string;
  readonly experiment?: string;
}

interface ScreenBySlugAndCustomerIdView {
  (args: ScreenBySlugAndCustomerIdViewArgs): Promise<ViewScreenBySlugAndCustomerIdResult>;
}

interface ViewScreenBySlugAndCustomerIdHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: ScreenBySlugAndCustomerIdView;
}

const viewScreenBySlugAndCustomerIdHandler: QueryHandlerFunction<
  ViewScreenBySlugAndCustomerId,
  ViewScreenBySlugAndCustomerIdResult,
  ViewScreenBySlugAndCustomerIdHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ slug, customerId, experiment }) =>
    view({ slug, customerId, experiment, signal });

export type { ViewScreenBySlugAndCustomerIdResult, ScreenBySlugAndCustomerIdView };
export { VIEW_SCREEN_BY_SLUG_AND_CUSTOMER_ID, viewScreenBySlugAndCustomerId, viewScreenBySlugAndCustomerIdHandler };
