// TODO properly export MessagingRoot from @lookiero/messaging-react
import React, { ComponentType, useCallback } from "react";
import { Platform } from "react-native";
import { useRoutes as reactRouterUseRoutes } from "react-router-native";
import { I18n } from "@lookiero/i18n-react";
import { LookAndLike } from "@lookiero/look-and-like";
import { MessagingRoot } from "@lookiero/messaging-react/bootstrap";
import { Locale } from "@lookiero/sty-psp-locale";
import { sentryLoggerHOC, sentryLogger, SentryEnvironment, SentryLoggerFunctionArgs } from "@lookiero/sty-psp-logging";
import { Layout } from "@lookiero/sty-psp-ui";
import { Customer } from "../../projection/customer/customer";
import { KameleoonEnvironment } from "../ab-testing/kameleoonEnvironment";
import { Routing } from "./routing/Routing";

interface RootFunctionArgs {
  readonly Messaging: MessagingRoot;
  readonly I18n: I18n;
  readonly development?: boolean;
  readonly sentry: () => SentryEnvironment;
  readonly kameleoon: () => KameleoonEnvironment;
}

interface RootFunction {
  (args: RootFunctionArgs): (args: SentryLoggerFunctionArgs) => ComponentType<RootProps>;
}

interface RootProps {
  readonly apiUrl: () => string;
  readonly getAuthToken: () => Promise<string>;
  readonly basePath: string;
  readonly customer: Customer | undefined;
  readonly layout: Layout;
  readonly locale?: Locale;
  readonly iOSInAppBrowserVideoAd?: boolean;
  readonly iOSInAppBrowserVideoAdBannerHeight?: number;
  readonly lookAndLike: LookAndLike;
  readonly onBack: () => void;
  readonly useRoutes?: typeof reactRouterUseRoutes;
}

const root: RootFunction = ({ Messaging, I18n, development, sentry, kameleoon: kameleoonConfig }) => {
  const logger = sentryLogger(sentry);
  const kameleoon = kameleoonConfig();

  const Root = ({
    apiUrl,
    getAuthToken,
    basePath,
    customer,
    layout,
    locale = Locale.en_GB,
    iOSInAppBrowserVideoAd = false,
    iOSInAppBrowserVideoAdBannerHeight = 0,
    lookAndLike,
    onBack,
    useRoutes = reactRouterUseRoutes,
  }: RootProps) => {
    const handleOnI18nError = useCallback((error: Error) => logger.captureException(error), []);

    return (
      <Messaging includeReactQueryDevTools={Platform.OS === "web"}>
        <Routing
          I18n={I18n}
          apiUrl={apiUrl}
          basePath={basePath}
          customer={customer}
          getAuthToken={getAuthToken}
          iOSInAppBrowserVideoAd={iOSInAppBrowserVideoAd}
          iOSInAppBrowserVideoAdBannerHeight={iOSInAppBrowserVideoAdBannerHeight}
          kameleoon={kameleoon}
          layout={layout}
          locale={locale}
          lookAndLike={lookAndLike}
          useRoutes={useRoutes}
          onBack={onBack}
          onI18nError={development ? undefined : handleOnI18nError}
        />
      </Messaging>
    );
  };

  const hoc = sentryLoggerHOC({ logger });

  /**
   * It's important to not call sentryLoggerHOC each time this function gets called.
   * That's why its called outside this method.
   */
  return ({ customerId }) => hoc({ customerId })(Root);
};

export type { RootProps };
export { root };
